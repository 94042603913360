import React from 'react'
import { Image } from '../../atoms/Image'
import { Label } from '../../atoms/Label'
import { Div } from '../Div'
import arrowdown from "../../../images/detailproject/arrow-down.png";
export const OptionsDetail = () => {
    return (
        <Div classname={"optionsdetail"}>
            <Div classname={"optionsdetail__areatext"}>
                <Label text={"Tareas"} classname={"optionsdetail__label"}/>
                <Image source={arrowdown} classname={"optionsdetail__image"}/>
            </Div>
            <Div classname={"optionsdetail__areatext"}>
                <Label text={"Asignado a"} classname={"optionsdetail__label"}/>
                <Label text={"Fecha"} classname={"optionsdetail__label"}/>
                <Label text={"Done"} classname={"optionsdetail__label"}/>
            </Div>
        </Div>
    )
}
