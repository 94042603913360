import React from "react";



import { Provider } from "react-redux";
import { AppRouter } from "./routers/AppRouter";
import { SnackbarProvider } from 'notistack';
import { store } from "./store/store";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
export const App = () => {


  return (
    <>
      <SnackbarProvider maxSnack={20}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </SnackbarProvider>
    </>
  );
};


