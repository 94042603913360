
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Lin } from "../../atoms/Lin";

import { OptionProjects } from "../../molecules/projects/OptionProjects";
import { Paginador } from "../../molecules/projects/Paginador";
import { ProjectsPage } from "../../organisms/projects/ProjectsPage";

const baseUrl = global.backendproject;
export const Projects = () => {
  const auth = useSelector((state) => state.auth);
  const [projects, setProjects] = useState(0);

  useEffect(() => {
    loadProject(1);
  }, []);

  const loadProject = async (page) => {
    const { data } = await axios.get(
      `${baseUrl}/api/v1/project/getprojectmodel?email=${auth.email}&page=${page}&maxPage=9&sort=DESC`,
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );
    setProjects(data);
  };

  return (
    <>
      <OptionProjects
        headers={{ header1: "Proyectos", header2: "Inicio / Proyectos" }}
      />
         
      {projects !== 0 ? (
        <>
     
          <ProjectsPage projects={projects} />
          <Paginador
            pageActual={projects?.pageable.pageNumber + 1}
            totalPages={projects?.totalPages}
            loadProject={loadProject}
          />
        </>
      ) : null}
    </>
  );
};
