import React from 'react'
import { Input } from '../../atoms/Input'
import { Div } from '../Div'

export const AssignmentInputPopover = ({placeholder,onChange,valueR}) => {
    return (
        <Div classname="inputpopover">
            <Input type="number" value={valueR} type="number"  placeholder={placeholder} classname="inputpopover__input" onChange={onChange} />
        </Div>
    )
}
