import { types } from "../types/types";

const initialState = {
  name: "",
  email: "",
  logged: null,
  roleId:null,
  role:"",
  groupId:null,
  group:"",
  img:""
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        name: action.payload.name,
        email: action.payload.email,
        logged: action.payload.logged,
        roleId:action.payload.roleId,
        role:action.payload.role,
        groupId:action.payload.groupId,
        group:action.payload.group,
        img:action.payload.img
      };

      case types.logout:
        return {
          name: action.payload.name,
          email: action.payload.email,
          logged: action.payload.logged,
          roleId:action.payload.roleId,
          role:action.payload.role,
          groupId:action.payload.groupId,
          group:action.payload.group,
          img:action.payload.img
        };

      default:
        return state;
  }
};
