import React from 'react'
import { Button } from '../../atoms/Button'
import { Image } from '../../atoms/Image'
import { Div } from '../Div'
import checkblack from '../../../images/detailproject/check-black.png'
import close from '../../../images/users/close.png';
export const Header = ({closeDetailProject}) => {
    return (
        <Div classname={"showdetailproject__main-header"}>
            <Button classname={"showdetailproject__header-button"}>
                <Image source={checkblack}/>
                Finalizar tarea
            </Button>
            <Image source={close} alt="Cerrar detalle de tarea" classname="showdetailproject__close" onClick={closeDetailProject}/>
        </Div>
    )
}
