import React from 'react'
import { Div } from '../../molecules/Div'
import { AssignmentCalendarBody } from '../../molecules/assignments/AssignmentCalendarBody';
import { AssignmentCalendarTask } from '../../molecules/assignments/AssignmentCalendarTask';
import arrowdown from "../../../images/assignment/arrow-down.svg";
import arrowup from "../../../images/assignment/arrow-up.svg";
import editgray from "../../../images/assignment/edit-gray.svg";
import editgreen from "../../../images/assignment/edit-green.svg";
import addicon from "../../../images/assignment/add-gray-icon.svg";
import saveicon from "../../../images/assignment/green-save-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import axios from "axios";
import { AssignmentCalendarTaskForm } from '../../molecules/assignments/AssignmentCalendarTaskForm';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';


export const AssignmentItem = ({ item, getInfo, allProjects, allSections, weeknumber,allUsers }) => {
    const baseUrlP = global.backendproject;
    const user = useSelector(state => state.auth)
    const [style, setStyle] = useState("assignment-item__hide")
    const [arrow, setArrow] = useState(arrowdown)
    const [edit, setEdit] = useState(editgray)
    const [disabledE, setDisabledE] = useState(true);
    const [disabledR, setDisabledR] = useState(true);
    const [changes, setChanges] = useState([])
    const [divFail, setDivFail] = useState("assignment-item__hide")
    const [divSuccess, setDivSuccess] = useState("assignment-item__hide")
    const [divFailText, setDivFailText] = useState("")
    const [divSuccessText, setDivSuccessText] = useState("")

    const [addTaskIcon, setAddTaskIcon] = useState(addicon)
    const [style2, setStyle2] = useState("assignment-item__hide")
    const [project, setProject] = useState({ id: 0, name: "" })
    const [section, setSection] = useState({ id: 0, name: "" })
    const [sectionList, setSectionList] = useState([])
    const [taskName, setTaskName] = useState("")
    const [formWeekSchedule, setFormWeekSchedule] = useState([])
    const [move, setmove] = useState(0)
    const [loader, setLoader] = useState(true);
    const [itemloader, setItemLoader] = useState(false);
    const [originalItem, setOriginalItem] = useState({ ...item })

    const selectProject = (itemP) => {
        setProject(itemP)
        let sectionL = [];
        for (let i = 0; i < allSections.length; i++) {
            if (allSections[i].project === itemP.id) {
                sectionL.push(allSections[i])
            }
        }
        setSectionList(sectionL)
        setSection({ id: 0, name: "" })
    }

    useEffect(() => {

        formatChanges();
        formatFormWeekSchedule();
        setOriginalItem(JSON.parse(JSON.stringify(item)))
    }, [item.task])

    const changeDisabled = () => {
        if (user.email === item.email) {
            if (disabledR) {
                setEdit(editgreen)
                setDisabledR(!disabledR)
                if (style === "assignment-item__hide") {
                    setStyle("assignment-item__show")
                    setArrow(arrowup)
                }
                if (user.roleId < 4) {
                    setDisabledE(!disabledE)
                }
            } else {
                if (validateScheduleChange()) {
                    setEdit(editgray)
                    setDisabledR(!disabledR)
                    if (style === "assignment-item__hide") {
                        setStyle("assignment-item__show")
                        setArrow(arrowup)
                    }
                    if (user.roleId < 4) {
                        setDisabledE(!disabledE)
                    }
                }
            }
        } else if (user.roleId < 4) {
            if (disabledE) {
                setEdit(editgreen);
                setDisabledE(!disabledE);
                if (style === "assignment-item__hide") {
                    setStyle("assignment-item__show")
                    setArrow(arrowup)
                }
            } else {
                if (validateScheduleChange()) {
                    setEdit(editgray);
                    setDisabledE(!disabledE);
                    if (style === "assignment-item__hide") {
                        setStyle("assignment-item__show")
                        setArrow(arrowup)
                    }
                }
            }
        }
    }

    const handleClick = () => {
        if (style === "assignment-item__hide") {
            setStyle("assignment-item__show")
            setArrow(arrowup)
        } else {
            setStyle("assignment-item__hide")
            setArrow(arrowdown)
        }
    }

    const changeSchedule = (idtask, date, realt, estimatedt, edit,comment) => {
        let changs = changes;
        for (let i = 0; i < changs.length; i++) {
            if (changs[i].idTask === idtask) {
                changs[i].comment=comment;
                changs[i].withChange = true;
                if (edit === "r") {
                    let founde = false;
                    for (let j = 0; j < changs[i].schedule.length; j++) {
                        if (changs[i].schedule[j].scheduleDate.substring(0, 10) === date) {
                            founde = true;
                            if (realt !== "") {
                                changs[i].schedule[j].real_time = realt;
                            } else {
                                changs[i].schedule[j].real_time = 0;
                            }
                        }
                    }
                    if (!founde) {
                        let newschedule = new Object();
                        newschedule.scheduleDate = date + "T12:00:00.707+00:00"
                        if (estimatedt !== "") {
                            newschedule.estimated_time = estimatedt
                        } else {
                            newschedule.estimated_time = 0
                        }
                        if (realt !== "") {
                            newschedule.real_time = realt
                        } else {
                            newschedule.real_time = 0
                        }
                        let sche = changs[i].schedule;
                        sche.push(newschedule);
                        changs[i].schedule = sche;
                    }
                }
                if (edit === "e") {
                    let found = false;
                    for (let j = 0; j < changs[i].schedule.length; j++) {
                        if (changs[i].schedule[j].scheduleDate.substring(0, 10) === date) {
                            found = true;
                            if (estimatedt !== "") {
                                changs[i].schedule[j].estimated_time = estimatedt;
                            } else {
                                changs[i].schedule[j].estimated_time = 0;
                            }
                        }
                    }
                    if (!found) {
                        let newschedule = new Object();
                        newschedule.scheduleDate = date + "T12:00:00.707+00:00"
                        if (estimatedt !== "") {
                            newschedule.estimated_time = estimatedt
                        } else {
                            newschedule.estimated_time = 0
                        }
                        if (realt !== "") {
                            newschedule.real_time = realt
                        } else {
                            newschedule.real_time = 0
                        }
                        let sche = changs[i].schedule;
                        sche.push(newschedule);
                        changs[i].schedule = sche;
                    }
                }
            }
        }
        setChanges(changs)
    }

    const validateScheduleChange = () => {
        let validated = true;
        let changs = changes;
        let totalweek = item.hoursWeek;
        let daysTotal = [];
        for (let i = 0; i < totalweek.length; i++) {
            let day = Object();
            day.thours = totalweek[i].estimatedh;
            day.date = totalweek[i].date;
            daysTotal.push(day)
        }
        for (let i = 0; i < daysTotal.length; i++) {
            for (let j = 0; j < changs.length; j++) {
                if (changs[j].withChange) {
                    for (let k = 0; k < changs[j].schedule.length; k++) {
                        if (daysTotal[i].date === changs[j].schedule[k].scheduleDate.substring(0, 10)) {
                            let number = daysTotal[i].thours;
                            let nsnumber = parseFloat(changs[j].schedule[k].estimated_time);
                            let oldNumber = getOldNumber(changs[j].idTask, daysTotal[i].date);
                            let newN = number - oldNumber + nsnumber;
                            daysTotal[i].thours = newN;
                        }
                    }
                }
            }
        }
        for (let w = 0; w < changs.length; w++) {
            if (changs[w].withChange) {
                for (let x = 0; x < changs[w].schedule.length; x++) {
                    if (typeof (changs[w].schedule[x].estimated_time) === "string") {
                        for(let y =0;y< daysTotal.length;y++){
                            if(daysTotal[y].thours > 10 && daysTotal[y].date ===changs[w].schedule[x].scheduleDate.substring(0, 10)){
                                validated = false;
                            }
                        }
                    }
                }
            }
        }
        if (validated) {
            sendChanges();
        } else {
            checkAssignment();
        }
        return validated;
    }

    const getOldNumber = (id, date) => {
        let tasklist = originalItem.task
        let numbero = 0;
        for (let i = 0; i < tasklist.length; i++) {
            if (tasklist[i].id === id) {
                for (let j = 0; j < tasklist[i].schedule.length; j++) {
                    if (tasklist[i].schedule[j].scheduleDate.substring(0, 10) === date) {
                        numbero = parseFloat(tasklist[i].schedule[j].estimated_time)
                    }
                }
            }
        }
        return numbero;
    }

    const sendChanges = async () => {
        let changs = changes;
        let changestopost = []
        for (let i = 0; i < changs.length; i++) {
            if (changs[i].withChange) {
                let changetopost = new Object();
                changetopost.id = changs[i].idTask;
                changetopost.comentario = changs[i].comment;
                let sche = []
                for (let j = 0; j < changs[i].schedule.length; j++) {
                    if (changs[i].schedule[j].estimated_time !== 0 ||
                        changs[i].schedule[j].real_time !== 0) {
                        sche.push(changs[i].schedule[j]);
                    }
                }
                changetopost.schedule = sche;
                changestopost.push(changetopost);
            }
        }
        const data = await axios.post(`${baseUrlP}/api/v1/task/sendassignment?startdate=${item.hoursWeek[0].date}&enddate=${item.hoursWeek[6].date}&email=${user.email}`, changestopost, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        });
        if (data.status === 201) {
            setDivSuccess("assignment-item__success")
            setDivSuccessText("Tus cambios han sido guardados exitosamente.")
            setTimeout(() => cleanDivs(), 2000)
            getInfo()
        } else {
            setDivFail("assignment-item__fail")
            setDivFailText("Hubo un error al momento de guardar tus cambios, intente nuevamente.")
            setTimeout(() => cleanDivs(), 2000)
        }
    }

    const formatChanges = () => {
        let changs = []
        for (let i = 0; i < item.task.length; i++) {
            let change = new Object();
            change.withChange = false;
            change.idTask = item.task[i].id;
            change.schedule = item.task[i].schedule;
            changs.push(change);
        }
        setChanges(changs);
    }

    const addTaskFunction = () => {
        if (user.roleId < 4) {
            if (addTaskIcon === addicon) {
                setAddTaskIcon(saveicon);
                setStyle2("assignment-item__show")
            } else {
                addNewTask();
            }
        }
    }

    const formatFormWeekSchedule = () => {
        let array = []
        for (let i = 0; i < item.hoursWeek.length; i++) {
            let arrayitem = new Object();
            arrayitem.scheduleDate = item.hoursWeek[i].date;
            arrayitem.estimated_time = 0;
            arrayitem.real_time = 0;
            array.push(arrayitem)
        }
        setFormWeekSchedule(array)
    }

    const changeScheduleForm = (date, estimatedt) => {
        let array = formWeekSchedule;
        for (let i = 0; i < array.length; i++) {
            if (date === array[i].scheduleDate) {
                array[i].estimated_time = estimatedt;
            }
        }
        setFormWeekSchedule(array);
        setmove(move + 1)
    }

    const addNewTask = () => {
        let validated = true;
        if (taskName === "") {
            validated = false;
        }
        if (project.name === "") {
            validated = false;
        }
        if (section.name === "") {
            validated = false;
        }
        let empty = true;
        for (let i = 0; i < formWeekSchedule.length; i++) {
            if (formWeekSchedule[i].estimated_time !== 0 &&
                formWeekSchedule[i].estimated_time !== "0" &&
                formWeekSchedule[i].estimated_time.trim() !== "") {
                empty = false
            }
        }
        if (empty) {
            validated = false;
        }
        if (validated) {
            validateOverSchedule();
        } else {
            setDivFail("assignment-item__fail")
            setDivFailText("Para poder crear una tarea debes deligenciar todos los campos y agendarla.")
            setTimeout(() => cleanDivs(), 2000)
        }
    }

    const validateOverSchedule = () => {
        console.log(formWeekSchedule)
        let validated = true;
        for (let i = 0; i < formWeekSchedule.length; i++) {
            for (let j = 0; j < item.hoursWeek.length; j++) {
                if (formWeekSchedule[i].scheduleDate.substring(0, 10) === item.hoursWeek[j].date) {
                    let number = 0
                    if (formWeekSchedule[i].estimated_time !== "") {
                        number = parseFloat(formWeekSchedule[i].estimated_time);
                    }
                    let totald = number + item.hoursWeek[j].estimatedh;
                    if (totald <= 10) {
                    } else {
                        if (number !== 0) {
                            validated = false;
                        }
                    }
                }
            }
        }
        if (validated) {
            FormatInfoToPost();
        } else {
            checkAssignment();
        }
    }

    const FormatInfoToPost = () => {
        let requestBody = new Object();
        requestBody.assigned = item.email;
        requestBody.name = taskName;
        requestBody.idProject = project.id;
        requestBody.idSection = section.id;
        requestBody.real_time = 0;
        let totale = 0;
        let schedule = [];
        let dueOn = "";
        let startOn = "";
        for (let i = 0; i < formWeekSchedule.length; i++) {
            if (formWeekSchedule[i].estimated_time !== 0 &&
                formWeekSchedule[i].estimated_time !== "0" &&
                formWeekSchedule[i].estimated_time.trim() !== "") {
                let sche = formWeekSchedule[i];
                if (formWeekSchedule[i].scheduleDate.length > 15) {
                    sche.scheduleDate = formWeekSchedule[i].scheduleDate;
                } else {
                    sche.scheduleDate = formWeekSchedule[i].scheduleDate + "T12:00:00.707+00:00";
                }
                totale += parseFloat(formWeekSchedule[i].estimated_time);
                schedule.push(sche);

                if (startOn === "" && dueOn === "") {
                    dueOn = sche.scheduleDate;
                } else if (startOn === "" && dueOn !== "") {
                    startOn = dueOn;
                    dueOn = sche.scheduleDate;
                } else if (startOn !== "" && dueOn !== "") {
                    dueOn = sche.scheduleDate;
                }
            }
        }
        requestBody.estimated_time = totale;
        requestBody.schedule = schedule;
        requestBody.dueOn = dueOn;
        requestBody.startOn = startOn;
        sendRequestBody(requestBody);
    }

    const sendRequestBody = async (requestBody) => {
        setLoader(false)
        try {
            const data = await axios.post(baseUrlP + `/api/v1/task/CreateTaskFromAssignment?email=` + user.email, requestBody, {
                headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
            });
            if (data.status === 201) {
                successFunction();
            } else {
                setDivFail("assignment-item__fail")
                setDivFailText("Hubo un error al momento de crear la tarea, intente nuevamente.")
                setTimeout(() => cleanDivs(), 2000)
                setLoader(true)

            }
        } catch (error) {
            setDivFail("assignment-item__fail")
            setDivFailText("Hubo un error al momento de crear la tarea, intente nuevamente.")
            setTimeout(() => cleanDivs(), 2000)
            setLoader(true)
        }
    }

    const successFunction = () => {
        setDivSuccess("assignment-item__success")
        setDivSuccessText("La tarea fue creada exitosamente.")
        setTimeout(() => cleanDivs(), 2000)
        setAddTaskIcon(addicon);
        setStyle2("assignment-item__hide");
        formatFormWeekSchedule();
        setTaskName("")
        setProject({ id: 0, name: "" })
        setSection({ id: 0, name: "" })
        setSectionList([])
        setmove(move + 1)
        setLoader(true)
        getInfo()
    }

    const cleanDivs = () => {
        setDivSuccess("assignment-item__hide")
        setDivSuccessText("")
        setDivFail("assignment-item__hide")
        setDivFailText("")
    }

    const cancelFunction = () => {
        setAddTaskIcon(addicon);
        setStyle2("assignment-item__hide");
        formatFormWeekSchedule();
        setTaskName("")
        setProject({ id: 0, name: "" })
        setSection({ id: 0, name: "" })
        setSectionList([])
        setmove(move + 1)
    }

    const checkAssignment = () => {
        setDivFail("assignment-item__fail")
        setDivFailText("El colaborador excede el límite de tiempo por día, por favor revise la asignación de la tarea.")
        setTimeout(() => cleanDivs(), 3000)
    }

    const syncUserInfo = async () => {
        setItemLoader(true);
        let url = baseUrlP + `/api/v1/task/getAlltaskModifiedTodayByUser?email=` + item.email;
        const { data } = await axios.get(url, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        });
        setItemLoader(false);
        getInfo();
    }

    const showMessage = (type,message) =>{
        let typeClass = (type=='S')?'assignment-item__success':'assignment-item__fail';
        setDivFail(typeClass)
        setDivFailText(message)
        setTimeout(() => cleanDivs(), 2000)
    }

    return (
        <Div classname="assignment-item">
            <AssignmentCalendarBody item={item} arrow={arrow} edit={edit}
                handleClick={handleClick} changeDisabled={changeDisabled} addTaskIcon={addTaskIcon}
                addTaskFunction={addTaskFunction} syncUserInfo={syncUserInfo} issync={itemloader} />
            <Div classname={style2}>
                <AssignmentCalendarTaskForm
                    allProjects={allProjects} days={item.hoursWeek} changeScheduleForm={changeScheduleForm}
                    project={project} section={section} setSection={setSection} sectionList={sectionList}
                    taskName={taskName} setTaskName={setTaskName} selectProject={selectProject}
                    formWeekSchedule={formWeekSchedule} cancelFunction={cancelFunction}
                    loader={loader} />
            </Div>
            <Div classname={style}>
                {item.task.map((taskitem) => (
                    <>
                        {weeknumber > 0 ? (
                            <AssignmentCalendarTask key={taskitem.id} item={taskitem} days={item.hoursWeek}
                                disabledE={disabledE} disabledR={disabledR} changeSchedule={changeSchedule}
                                weeknumber={weeknumber}  assigned={item.email} allUsers={allUsers} showMessage={showMessage} >
                                </AssignmentCalendarTask>

                        ) : (
                            <>
                                {!taskitem.completed && (
                                    <AssignmentCalendarTask key={taskitem.id} item={taskitem} days={item.hoursWeek}
                                        disabledE={disabledE} disabledR={disabledR} changeSchedule={changeSchedule}
                                        weeknumber={weeknumber}  assigned={item.email} allUsers={allUsers} showMessage={showMessage} >
                                        </AssignmentCalendarTask>
                                )}
                            </>
                        )}
                    </>
                ))}
            </Div>
            <Div classname={divSuccess}>
                {divSuccessText}
            </Div>
            <Div classname={divFail}>
                {divFailText}
            </Div>
        </Div>
    )
}
