import React from "react";
import { Div } from "../../molecules/Div";
import { AddTaskBoard } from "../../molecules/detailproject/AddTaskBoard";
import { OptionBoard } from "../../molecules/detailproject/OptionBoard";
import { TaskBoard } from "../../molecules/detailproject/TaskBoard";

import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "../../atoms/Button";
import { Input } from "../../atoms/Input";

const Item = styled.div``;

const List = styled.div``;

const Container = styled(List)``;

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

export const DetailProjectBoard = ({
  onDragEnd,
  task,
  newSection,
  changenNewSection,
  createNewSection,
  newTask,
  selectTask
}) => {

  return (
    <Div classname="board">
      <DragDropContext onDragEnd={onDragEnd}>
        {task.map((list, i) => (
          <Droppable key={list.id} droppableId={list.id}>
            {(provided, snapshot) => (
              <Container
                innerRef={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                className="board__content"
              >
                <OptionBoard title={list.name} id={list.id} newTask={newTask} />
                {list.taskList.length
                  ? list.taskList.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Item
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                            {...provided.dragHandleProps}
                            className="board__column"
                          >
                            <TaskBoard task={item} selectTask={selectTask}/>
                          </Item>
                        )}
                      </Draggable>
                    ))
                  : !provided.placeholder && <Notice>Drop items here</Notice>}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <Div classname="newsection">
        <Input
          classname="newsection__input"
          placeholder="Nueva sección"
          value={newSection}
          onChange={changenNewSection}
        />
        <Button classname="newsection__addsection" onClick={createNewSection}>
          + Agregar sección
        </Button>
      </Div>
    </Div>
  );
};
