import React, { useState } from "react";
import { Div } from "../../molecules/Div";
import { BodyTableUsers } from "../../molecules/users/BodyTableUsers";
import { HeaderTableUsers } from "../../molecules/users/HeaderTableUsers";

export const TableUsers = ({ users, selectUserToEdit, editUser,inacUser }) => {
  return (
    <Div classname="tableuser">
      <HeaderTableUsers />
      {users.map((user, index) => (
        <BodyTableUsers
          key={index}
          user={user}
          selectUserToEdit={selectUserToEdit}
          editUser={editUser}
          inacUser={inacUser}
        />
      ))}
    </Div>
  );
};
