import React from "react";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";
import { Lin } from "../../atoms/Lin";
import { horaMes } from "../../../helpers/horaMes";
import { useHistory } from "react-router";

export const Project = ({ project,styles }) => {

  const history = useHistory();
  const redirecSectionProject = () => {
    history.replace(`/detailproject/${project?.id}`);
  }
  return (
    <Div classname={"project"} onClick={redirecSectionProject}>
      <Div classname={`project__content ${styles}`}>
        <Div classname={"project__content-head"}>
          <Div classname={"project__content-head-title"}>Visitado {horaMes( project?.modified )}</Div>
        </Div>
        <Div classname={"project__content-footer"}>
          {project?.user.map((u, index) =>
            u?.photo !== null ? (
              <Div key={index} classname={"project__content-footer-item"}>
                {u?.photo?.length > 1 ? (
                  <Image
                    source={u.photo}
                    classname="project__content-footer-item-img"
                  />
                ) : (
                  u?.name.slice(0, 1)
                )}
              </Div>
            ) : null
          )}
        </Div>
      </Div>
      <Div classname={"project__footer"}>
     
          {project?.name}
  
      </Div>
    </Div>
  );
};
