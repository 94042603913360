import React from "react";
import { Label } from "../../atoms/Label";
import { OptionProject } from "../../molecules/inicio/OptionProject";
import { OptionsHomeTasks } from "../../organisms/home/OptionsHomeTasks";
import { Projects } from "../../organisms/home/Projects";
import { Tasks } from "../../organisms/home/Tasks";

export const HomePage = () => {
  return (
    <>
      <Label classname={"content__label"} text={"Inicio"} />
      <OptionsHomeTasks />
      <Tasks />
      <OptionProject />
      <Projects />
    </>
  );
};
