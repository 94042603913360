import React, { useState } from "react";
import { Input } from "../../atoms/Input";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import { DateRange } from "react-date-range";
import { Button } from "../../atoms/Button";
import axios from "axios";
import { horaMesCalendar } from "../../../helpers/horaMes";



const baseSearch = global.backenduser;
export const AddTask = ({addNewTask, closeAddTask}) => {
  const [form, setForm] = useState({
    task: "",
    responsable: {
      active: true,
      asanaId: "",
      email: "",
      groupId: 0,
      name: "",
      photo: "",
      rolId: 0,
    },
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
  });
  const [state, setState] = useState(false);
  const [users, setUsers] = useState([]);

  const assignNameTask = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const searchUser = async (e) => {

    setForm({
      ...form,
      responsable: {
        ...form.responsable,
        name: e.target.value
      }
     
    });
    if (e.target.value.length > 3) {
      let { data } = await axios.get(
        `${baseSearch}/api/v1/user/search/${e.target.value}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
     
      setUsers(data);
    } else {
      setUsers([]);
    }
  };
  const assignUser = (user) => {
   
    setForm({
      ...form,
      responsable: user,
    });
    setUsers([]);
  };



  const changeDate = (item) => {
    setForm({
      ...form,
      date: [item.selection],
    });

    if (item.selection.endDate !== item.selection.startDate) {
      setState(!state);
    }
  };
  return (
    <Div classname="addtask" >
      <Div classname="addtask__groupform">
        <Label text={"Nombre de la tarea:"} classname="addtask__label" />
        <Input
          placeholder="Nombre tarea"
          classname="addtask__input"
          name="task"
          onChange={assignNameTask}
        />
      </Div>
      <Div classname="addtask__groupform">
        <Label text={"Responsable:"} classname="addtask__label" />
        <Input
          placeholder="Responsable"
          classname="addtask__input"
          onChange={searchUser}
          name={"responsable"}
          value={form.responsable.name}
        />
        {users.length > 0 ? (
          <Div classname="addtask__listusers">
            {users.map((user, index) => (
              <Div
                key={index}
                classname="addtask__user"
                onClick={() => assignUser(user)}
              >
                {user.name}
              </Div>
            ))}
          </Div>
        ) : null}
      </Div>
      <Div classname="addtask__groupform">
        <Div>
          <Label text={"Fecha:  "} classname="addtask__label" />
          <Label
            text={`${horaMesCalendar(
              form.date[0].startDate
            )} - ${horaMesCalendar(form.date[0].endDate)}`}
            classname="addtask__assigndate"
            onClick={() => setState(!state)}
          />
        </Div>

        {state ? (
          <Div classname={"addtask__date-range"}>
            <DateRange
              editableDateInputs={false}
              onChange={(item) => changeDate(item)}
              moveRangeOnFirstSelection={false}
              ranges={form.date}
              rangeColors={["#80E593"]}
            />
          </Div>
        ) : null}
      </Div>
      <Div classname="addtask__buttons">
        <Button classname="addtask__button-cancel" onClick={closeAddTask}>Cancelar</Button>
        <Button classname="addtask__button-confirm" onClick={() => addNewTask(form)}>
          Confirmar
        </Button>
      </Div>
    </Div>
  );
};
