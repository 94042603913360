import React from "react";

export const Input = ({ placeholder, classname, name, onChange, value, onKeyPress,
                        type, disabled, onKeyDown,onBlur,onClick}) => {
  return (
    <input
      placeholder={placeholder}
      className={classname}
      name={name}
      onChange={onChange}
      value={value}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      type={type}
      disabled={disabled}
      onBlur={onBlur}
      onClick={onClick}
    />
  );
};

