import React, { useState } from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import addoption from "../../../images/detailproject/addcircle.png";
import menuoption from "../../../images/detailproject/menupoints.png";
import { AddTask } from "./AddTask";

export const OptionBoard = ({ title, id, newTask }) => {
  const [task, setTask] = useState(false);

  const addNewTask = (form) => {
    if (
      form.task.length > 0 &&
      form.responsable.email.length > 0 &&
      form.date[0].startDate !== form.date[0].endDate
    ) {
  
      newTask(id, form);
      setTask(!task);
    }
  };

  const closeAddTask = () => {
    setTask(!task);
  }
  return (
    <Div classname="optionboard">
      <Label text={title} classname="optionboard__label" />
      <Div classname="optionboard__options">
        <Image
          source={addoption}
          onClick={() => setTask(!task)}
          classname="optionboard__add"
        />
        <Image source={menuoption} />
      </Div>
      {task ? <AddTask  addNewTask={addNewTask} closeAddTask={closeAddTask}/> : null}
    </Div>
  );
};
