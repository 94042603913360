import React from 'react'
import { Div } from "../Div";
import { Label } from "../../atoms/Label";
import { Image } from "../../atoms/Image";
import { AssignmentInput } from './AssignmentInput';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from 'react'
import unlikeicon from './../../../images/assignment/unlike-gray-icon.svg'
import unlikeicon2 from './../../../images/assignment/unlike-dark-icon.svg'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from '../../atoms/Button';
import axios from 'axios';
import { AssignmentFormSelect } from './AssignmentFormSelect';
import commenticon from '../../../images/assignment/comment.png';
import { AssignmentCalentarDialogStory } from './AssignmentCalentarDialogStory';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export const AssignmentCalendarTask = ({ item, days, disabledE, disabledR, changeSchedule, weeknumber, assigned,allUsers,showMessage }) => {
  const user = useSelector(state => state.auth)
  const classes = useStyles();
  const [openUnlike, setOpenUnlike] = useState(false);
  const [open,setOpen] = useState(false)
  const [link, setLink] = useState("")
  const [textAreaStyle, setTextAreaStyle] = useState("assigncalentask__m-textarea")
  const [comment, setComment] = useState("")
  const [divFail, setDivFail] = useState("assigncalentask__hide")
  const [divSuccess, setDivSuccess] = useState("assigncalentask__hide")
  const [whoIsReturned, setWhoIsReturned] = useState({name:"",email:""})
  const [typeReturn, setTypeReturn] = useState(0)
  const baseUrlP = global.backendproject;
  const [storys,setStorys]=useState([]);
  const handleStoryOpen = () => setOpen(true);
  const handleStoryClose = () => setOpen(false);  
  const [loadingStoryTask, setLoadingStoryTask] = useState(false);


  const handleOpenUnlike = (a) => {
    if(a!==""){
      setWhoIsReturned({name:"",email:a})
      setTypeReturn(1)
    } else{
      setTypeReturn(2)
    }
    setOpenUnlike(true); 
  };

  const handleClose = () => { setOpenUnlike(false); };

  useEffect(() => {
    getLink()
  }, [])

  const getLink = () => {
    if (item.idProject !== "" && item.idProject !== null && item.idProject !== undefined) {
      let l = "https://app.asana.com/0/" + item.idProject + "/" + item.id;
      setLink(l)
    }
  }

  const getCalendarTask = (i) => {
    let tr = -1;
    let te = -1;
    for (let j = 0; j < item.schedule.length; j++) {
      if (item.schedule[j].scheduleDate.substring(0, 10) === i.date) {
        tr = item.schedule[j].real_time;
        te = item.schedule[j].estimated_time;
      }
    }
    if (tr === -1 && te === -1) {
      return (
        <AssignmentInput key={i.date + item.id}
          initvalueE="" initclassnameE="assigncalentask__empty" disabledE={disabledE}
          initvalueR="" initclassnameR="assigncalentask__empty" disabledR={disabledR}
          date={i.date} changeSchedule={changeSchedule} taskid={item.id} showMessage={showMessage}
          hoursDay={i.estimatedh}></AssignmentInput>
      )
    } else {
      return (
        <AssignmentInput key={i.date + item.id}
          initvalueE={te} initclassnameE="assigncalentask__est" disabledE={disabledE} 
          initvalueR={tr} initclassnameR="assigncalentask__real" disabledR={disabledR}
          date={i.date} changeSchedule={changeSchedule} taskid={item.id} showMessage={showMessage}
          hoursDay={i.estimatedh}></AssignmentInput>
      )
    }
  }

  const goToTask = () => {
    window.open(link, '_blank');
  }

  const handleTextArea = (ev) => {
    setComment(ev.target.value);
    setTextAreaStyle("assigncalentask__m-textarea")
  }

  const sendComment = () => {
    if (comment.length < 4) {
      setTextAreaStyle("assigncalentask__m-textarea-error")
    } else {
      if (whoIsReturned.email!==""){
        postComment()
      }
    }
  }

  const postComment = async () => {
    console.log(user)
    console.log(item)
    console.log(whoIsReturned)
    let commentpost = { taskId: item.id, text: comment, whoReturns: user.email, whoIsReturned:whoIsReturned.email  }
    let url = global.backendproject + "/api/v1/TaskRejected/save";
    const data = await axios.post(
      url, commentpost, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    )
    if (data.status === 201) {
      successF()
    } else {
      FailF()
    }
  }

  const successF = () => {
    setOpenUnlike(false)
    setDivSuccess("assigncalentask__success")
    setTimeout(() => setDivSuccess("assigncalentask__hide"), 3000)
  }

  const FailF = () => {
    setOpenUnlike(false)
    setDivFail("assigncalentask__fail")
    setTimeout(() => setDivFail("assigncalentask__hide"), 3000)
  }

  const getStoriesByTask = async () => {
    setLoadingStoryTask(true);
    try {
        const data = await axios.get(baseUrlP + `/api/v1/story/findByIdTaskOnly/`+item.id+`?email=` + user.email, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        });
        if (data.status === 200) {
          if(data.data.length>0){
            setStorys(data.data);
            setOpen(true);
          }else{
            showMessage('F',"Esta tarea aun no registra comentarios.");
          }
        } else {
            showMessage('F',"Hubo un error al cargar los comentarios de la tarea, intente nuevamente.");
        }
    } catch (error) {
        showMessage('F',"Hubo un error al cargar los comentarios de la tarea, intente nuevamente.");
    }
    setLoadingStoryTask(false);
}  

  return (
    <Div classname="assigncalentask">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openUnlike}
        onClose={handleClose}
      >
        <Div classname="assigncalentask__paper">
          <Div classname="assigncalentask__m-header">Reprocesar tarea</Div>
          {typeReturn===2&&(
            <Div classname="assigncalentask__m-select">
                <Div classname="assigncalentask__m-body">Por favor señale quien le asigno la tarea</Div>
                <AssignmentFormSelect title="" list={allUsers} item={whoIsReturned} setItem={setWhoIsReturned}></AssignmentFormSelect>
            </Div>
          )}
          <Div classname="assigncalentask__m-body">Por favor, añade un comentario que justifique el reproceso de la tarea : <b>"{item.name}"</b>.</Div>
          <textarea className={textAreaStyle} rows="6" onChange={(ev) => handleTextArea(ev)}></textarea>
          <Div classname="assigncalentask__m-buttons">
            <Button classname="assigncalentask__m-green-button" onClick={sendComment}> Aceptar </Button>
            <Button classname="assigncalentask__m-gray-button" onClick={handleClose}> Cancelar </Button>
          </Div>
        </Div>
      </Modal>
      <Div classname={divSuccess}>
        Reproceso reportado exitosamente
      </Div>
      <Div classname={divFail}>
        Hubo un error al momento de reportar el reproceso
      </Div>
      <Div classname="assigncalentask__members">
        {user.roleId < 4 && (<div>
          <Image source={unlikeicon} classname="assigncalentask__unlike-icon" onClick={(ev)=>{handleOpenUnlike(assigned)}} />
        </div>)}
        {user.email === assigned && (<div>
          <Image source={unlikeicon2} classname="assigncalentask__unlike-icon2" onClick={(ev)=>{handleOpenUnlike("")}} />
        </div>)}
        {link ?
          <Div classname="assigncalentask__task-link" onClick={goToTask}>{item.name}</Div>
          :
          <Div>{item.name}</Div>
        }
        {item.projectName ?
          <Div classname="assigncalentask__project">
            {item.projectName}
          </Div>
          : null}
          <Image source={commenticon} onClick={getStoriesByTask} classname={"assigncalentask__logo-comment"} />
          <Fade
            in={loadingStoryTask}
            style={{
              transitionDelay: '0ms' ,
            }}
            unmountOnExit
          >
            <CircularProgress size={20} color="primary" />
          </Fade>          
      </Div>
      <Div classname="assigncalentask__arrow">
        <Label text={"EST"} classname="assigncalentask__arrow-label" />
        <Label text={"REAL"} classname="assigncalentask__arrow-label" />
      </Div>
      {days.map((day) => (
        getCalendarTask(day)
      ))}
      <Div classname="assigncalentask__sum">{item.totale}</Div>
      <Div classname="assigncalentask__sum-total">{item.totalr}</Div>
      <AssignmentCalentarDialogStory
         open={open}
         handleClose={handleStoryClose}
         item={item}
         storys={storys}
       />
    </Div>
  )
}