import React from "react";

import { Div } from "../../molecules/Div";
import { Project } from "../../molecules/projects/Project";

export const ProjectsPage = ({ projects }) => {
  let backgrounds = [
    "project__content1",
    "project__content2",
    "project__content3",
  ];
  let iterador = -1;
  return (
    <Div classname={"projects"}>
      {projects.content.length > 1
        ? projects?.content.map((project, index) => {
            if (iterador === 2) {
              let deleted = backgrounds.splice(2, 1);
              backgrounds.unshift(deleted);
              iterador = -1;
            }
            iterador++;
            return (
              <Project
                key={index}
                project={project}
                styles={backgrounds[iterador]}
              />
            );
          })
        : null}
    </Div>
  );
};
