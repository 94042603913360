import React from "react";
import { Div } from "../Div";
import { Label } from "../../atoms/Label";
import { Image } from "../../atoms/Image";
import syncicon from "../../../images/assignment/arrow-repeat-gray.svg"
import syncicongreen from "../../../images/assignment/arrow-repeat-green.svg"

import { AssignmentCalendarBodyDay } from "./AssignmentCalendarBodyDay";
export const AssignmentCalendarBody = ({ item, arrow, edit, handleClick, changeDisabled, addTaskIcon,
  addTaskFunction, syncUserInfo, issync }) => {
    const format = (a) =>{
      let array = a.toString().split(".");
      if(array[1]===undefined){
          return array[0]
      }else{
          let n = array[0]+'.'+array[1].substring(0,2)
          return n
      }
  }
  return (
    <Div classname="assigncalenbody">
      <Div classname="assigncalenbody__members">
        <Div classname="assigncalenbody__name-item">
          {item.name.substr(0, 1)}
        </Div>
        {item.name}
      </Div>
      <Div classname="assigncalenbody__space_change">
        <Image source={arrow} onClick={handleClick} />
      </Div>
      <Div classname="assigncalenbody__space_change" >
        <Image source={edit} onClick={changeDisabled} />
      </Div>
      <Div classname="assigncalenbody__space_change" >
        <Image source={addTaskIcon} onClick={addTaskFunction} />
      </Div>
      {issync ? (
        <Div classname="assigncalenbody__space_change" >
          <Image source={syncicongreen} classname="assigncalenbody__sync-icon imgr" onClick={syncUserInfo} />
        </Div>
      ) : (
          <Div classname="assigncalenbody__space_change" >
            <Image source={syncicon} classname="assigncalenbody__sync-icon" onClick={syncUserInfo} />
          </Div>
        )}
      <Div classname="assigncalenbody__arrow">
        <Label text={"EST"} classname="assigncalenbody__arrow-label" />
        <Label text={"REAL"} classname="assigncalenbody__arrow-label" />
      </Div>
      <AssignmentCalendarBodyDay item={item.hoursWeek[0]} />
      <AssignmentCalendarBodyDay item={item.hoursWeek[1]} />
      <AssignmentCalendarBodyDay item={item.hoursWeek[2]} />
      <AssignmentCalendarBodyDay item={item.hoursWeek[3]} />
      <AssignmentCalendarBodyDay item={item.hoursWeek[4]} />
      <AssignmentCalendarBodyDay item={item.hoursWeek[5]} />
      <AssignmentCalendarBodyDay item={item.hoursWeek[6]} />
      <Div classname="assigncalenbody__sum">{format(item.totale)}</Div>
      <Div classname="assigncalenbody__sum-total">{format(item.totalr)}</Div>
    </Div>
  );
};