import React, { useState } from "react";
import { Label } from "../../atoms/Label";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";
import { horaMesCalendar } from '../../../helpers/horaMes';
import taskimage from "../../../images/inicio/task.png";

import { DateRange } from "react-date-range";
import { Input } from "../../atoms/Input";
import axios from "axios";

const baseSearch = global.backenduser;
export const HeaderActivity = ({task,sendInfoUser}) => {

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(task.asigned.name);
  const [dateLabel, setDateLabel] = useState({
    startDate: task.start_on == null ? null : horaMesCalendar(task.start_on),
    endDate: task.due_on == null ? null : horaMesCalendar(task.due_on),
  });

  const [date, setDate] = useState([
    {
      startDate:
        task.start_on == null
          ? task.due_on == null
            ? new Date()
            : new Date(task.due_on)
          : new Date(task.start_on),
      endDate: task.due_on == null ? new Date() : new Date(task.due_on),
      key: "selection",
    },
  ]);
  const [state, setstate] = useState(false);

  const searchUser = async (e) => {
    setUser(e.target.value);
    if (e.target.value.length > 4) {
      let { data } = await axios.get(
        `${baseSearch}/api/v1/user/search/${e.target.value}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
  
      setUsers(data);
   
    }else{
      setUsers([]);
    }
    
  };

  const assignUser = (value) => {
    sendInfoUser(value)
    setUser(value.name);
    setUsers([]);
  }

  const changeDate = (item) => {
  
    setDate([item.selection]);
    if(item.selection.startDate !== item.selection.endDate){
      setstate(!state)
    }
  }
  return (
    <Div classname="headeractivity">
      <Div classname="headeractivity__content">
        <Div classname={"headeractivity__perfil"}>
          <Input
            classname="headeractivity__input"
            name={"responsable"}
            value={user}
            onChange={searchUser}
          />
          {users.length > 0 ? (
            <Div classname="headeractivity__listsuser">
              {users.map((element, index) => (
                <Div key={index} classname="headeractivity__listuser" onClick={() => assignUser(element)}>
                  {element.name}
                </Div>
              ))}
            </Div>
          ) : null}
        </Div>
        <Div classname="headeractivity__dates">
          <Div classname={"headeractivity__date"}>
            <Label
              text={
                dateLabel.startDate == null && dateLabel.endDate != null
                  ? dateLabel.endDate
                  : dateLabel.startDate != null && dateLabel.endDate != null
                  ? `${dateLabel.startDate} - ${dateLabel.endDate}`
                  : "Sin asignar"
              }
              onClick={() => setstate(!state)}
              classname={"headeractivity__date-label"}
            />
            {state ? (
              <Div classname={"headeractivity__date-range"}>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => changeDate(item)}
                  moveRangeOnFirstSelection={false}
                  ranges={date}
                  rangeColors={["#80E593"]}
                />
              </Div>
            ) : null}
          </Div>
        </Div>
        <Div classname="headeractivity__projects">
          <Div classname="headeractivity__projects-title">
            <Div classname="headeractivity__projects-image">
              <Image source={taskimage} classname="headeractivity__projects-img" />
            </Div>
            {task.projectName}
          </Div>

        </Div>
      
      </Div>
    </Div>
  );
};
