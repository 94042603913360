import React from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import checkblack from '../../../images/assignment/gray-check.svg';
import arrowleft from '../../../images/assignment/arrow-left-gray.svg';
import arrowright from '../../../images/assignment/arrow-right-gray.svg';


export const AssignmentTableHeader = ({range,weekLess, weekPlus}) => {
  return (
    <Div classname={"assigntableheader"}>
      <Div classname={"assigntableheader__dates"}>
        <Div classname={"assigntableheader__date"}>
          {range}
        </Div>
        <Div classname="assigntableheader__arrows">
          <Div classname="assigntableheader__arrow" onClick={weekLess}>
            <Image source={arrowleft} />
          </Div>
          <Div classname="assigntableheader__arrow" onClick={weekPlus}>
            <Image source={arrowright}/>
          </Div>
        </Div>
      </Div>
      <Div classname={"assigntableheader__hours-space-2"}>
{/*         <Div classname={"assigntableheader__check"}>
          <Image source={checkblack} />
          <Label text={"Over Allocated (+8)"} />
        </Div>
        <Div classname={"assigntableheader__check"}>
          <Image source={checkblack} />
          <Label text={"Fully Allocated (8)"} />
        </Div> */}
      </Div>
    </Div>
  );
};
