import React, { useState } from "react";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";
import imageperfil from "../../../images/detailproject/imageperfil.png";
import time from "../../../images/inicio/time.png";
import save from "../../../images/users/save.png";
import close from "../../../images/users/close.png";
import { Span } from "../../atoms/Span";
export const BodyTableUsers = ({ user, selectUserToEdit, editUser,inacUser }) => {
  const [option, setOption] = useState(false);

  const [supruser, setSupruser] = useState(false);

  const selectUser = () => {
    selectUserToEdit(user);
    setOption(!option);
  };

  const saveUser = () => {
    editUser();
    setOption(!option);
  };
  return (
    <Div classname="bodytableusers">
      <Div classname="bodytableusers__user">
        <Div classname="bodytableusers__user-text">
          <Image source={imageperfil} classname="bodytableusers__img" />
          {user.name}
        </Div>
        {option ? (
          <Div classname="bodytableusers__options1">
            <Image
              source={save}
              onClick={saveUser}
              classname="bodytableusers__options_edit"
            />
          </Div>
        ) : (
          <Div classname="bodytableusers__options2">
            <Image
              source={time}
              onClick={selectUser}
              classname="bodytableusers__options_edit"
            />
          </Div>
        )}
      </Div>
      <Div classname="bodytableusers__body">{user.email}</Div>
      <Div classname="bodytableusers__body">
        {user.rolId === 1
          ? "ADMIN"
          : user.rolId === 2
          ? "ECOMMERCE_MANAGER"
          : user.rolId === 3
          ? "LEADER"
          : user.rolId === 4
          ? "REGULAR"
          : null}
      </Div>
      
      <Div classname="bodytableusers__body">
        {user.groupId === 1
          ? "FRONTEND"
          : user.groupId === 2
          ? "BACKEND"
          : user.groupId === 3
          ? "DISEÑO"
          : user.groupId === 4
          ? "PAUTA"
          : user.groupId === 5
          ? "ECOMMERCE_MANAGER"
          : user.groupId === 6
          ? "SEO"
          : user.groupId === 7
          ? "DEVOPS"
          : user.groupId === 8
          ? "COMERCIAL"
          : user.groupId === 10
          ? "PROCESOS&QA"
          : null}
          
      </Div>
      <Div classname="bodytableusers__body">{user.accessToken}</Div>
      <Div classname={supruser ? "bodytableusers__options": "bodytableusers__body"}>
        {supruser ? (
          <>
            <Span classname="bodytableusers__ok" onClick={()=>inacUser(user.email)}>Inactivar</Span>
            <Span classname="bodytableusers__cancel" onClick={() => setSupruser(!supruser)}>Cancelar</Span>
          </>
        ) : (
          <Image source={close} classname="bodytableusers__delete" onClick={() => setSupruser(!supruser)}/>
        )}
      </Div>
    </Div>
  );
};
