import React from "react";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";
import rectangle1 from "../../../images/detailproject/rectangle1.png";
import rectangle2 from "../../../images/detailproject/rectangle2.png";
import rectangle3 from "../../../images/detailproject/rectangle3.png";
import plus from "../../../images/detailproject/bi_plus.png";
import { InputFile } from "../../atoms/InputFile";
import { Button } from "../../atoms/Button";
import axios from "axios";
import { useSelector } from "react-redux";
import uuid from "uuid/v4";

const baseUrl = global.backendproject;
export const ResourcesDetailProject = ({
  attachments,
  addNewResourceLocal,
  idTask,
}) => {
  const user = useSelector((state) => state.auth);
  const loadNewResource = (e) => {
    const fileload = e.target.files[0];
    const newResource = {
      created_at: new Date(),
      download_url: "",
      host: "asana",
      id: uuid(),
      idTask: idTask,
      name: fileload.name,
      resource_type: "attachment",
      view_url: "",
    };

    addNewResourceServer(fileload);
    addNewResourceLocal(newResource);
  };
  const addNewResourceServer = async (resource) => {
    const formData = new FormData();

    formData.append("file", resource);

    const { data } = await axios.post(
      `${baseUrl}/api/v1/attachment/upload?taskId=${idTask}&email=${user.email}`,
      formData,
      {
        headers: {
          Authorization: "Basic YWRtaW46YWRtaW4=",
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const downLoad = async (url) => {
    const {data } = await axios.get(url, {
      headers: {
        responseType: "blob",
      },
    });
    URL.createObjectURL(new Blob([data]))

  };
  return (
    <Div classname={"resourcesProject"}>
      {attachments?.attachments.map((attachment) => (
        <Div
          classname={"resourcesProject__content-image"}
          key={attachment.id}
          onClick={() => downLoad(attachment.download_url)}
        >
          {attachment.name}
        </Div>
      ))}
      <Div classname="resourcesProject__content-upload">
        <Button classname="resourcesProject__content-add">
          <Image
            source={plus}
            alt={"add resource"}
            classname="resourcesProject__icon-add"
          />
        </Button>
        <InputFile
          className={"resourcesProject__inputfile"}
          onChange={loadNewResource}
        />
      </Div>
    </Div>
  );
};
