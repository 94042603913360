import React, { useState } from "react";
import { Button } from "../../atoms/Button";
import { Label } from "../../atoms/Label";
import { Span } from "../../atoms/Span";
import { Div } from "../Div";
import { FilterOptions } from "./FilterOptions";

export const OptionsDetailBoardList = ({
  handleListBoard,
  project,
  filter,
  changeFilter,
  listBoard,
  closeDetailProject
}) => {
  const [options, setOptions] = useState(false);

  const selectFilter = (option) => {
    changeFilter(option);
    setOptions(!options);
  };
  return (
    <Div classname={"optiondetail"}>
      <Div classname={"optiondetail__textarea"}>
        <Label text={project} classname={"optiondetail__title"} />
        <Label
          text={`Proyectos / ${project}`}
          classname={"optiondetail__subtitle"}
        />
      </Div>
      <Div classname={"optiondetail__buttonarea"}>
        <Button
          classname={listBoard === "board" ? "optiondetail__board-active" : "optiondetail__board"}
          onClick={() => handleListBoard("board")}
        >
          Tablero
        </Button>
        <Button
         classname={listBoard === "list" ? "optiondetail__list-active" : "optiondetail__list"}
          onClick={() => handleListBoard("list")}
        >
          Lista
        </Button>
        <Div classname="optiondetail__filter">
          <Button
            classname="optiondetail__filter-button"
            onClick={() => setOptions(!options)}
          >
            {filter === "all"
              ? "Todas las tareras"
              : filter === "incomplete"
              ? "Tareas sin finalizar"
              : filter === "completed"
              ? "Tareas finalizadas"
              : null}
          </Button>
          {options ? <FilterOptions selectFilter={selectFilter} setOptions={setOptions} options={options}/> : null}
        </Div>
        
      </Div>
    </Div>
  );
};
