import React from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Span } from "../../atoms/Span";
import { Div } from "../Div";
import arrowdown from "../../../images/assignment/arrow-down.svg";
import { Button } from "../../atoms/Button";
import {useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";


export const AssignmentHeader = ({teamselected,setTeamSelected}) => {
  const baseUrl = global.backenduser;
  const [teams, setTeams] = useState([])
  const user = useSelector(state => state.auth)
  const [style, setstyle] = useState("header-assignment__hide")
  useEffect(() => {
    if(teams.length===0){
      getTeams()
    }
  }, [])

  const handleClick = () =>{
    if(style==="header-assignment__hide"){
      setstyle("header-assignment__show")
    }else{
      setstyle("header-assignment__hide")
    }
  }

  const getTeams = async() =>{
    const { data } = await axios.get(baseUrl+`/api/v1/group/findGroupsByUser/`+user.email, {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      });
      setData(data);
}

const setData = (data) =>{
  if(data.length>1){
    setTeams(data);
    setTeamSelected(data[0])
  }else{
    let na = [];
    na.push(data)
    setTeams(na)
    if(na.length>0){
      setTeamSelected(na[0])
    }
  }
}

const setTeamS = (team) =>{
  setTeamSelected(team);
  handleClick();
}
  return (
    <Div classname="header-assignment">
      <Label text={"Asignaciones"} classname="header-assignment__title" />
      <Div classname={"header-assignment__select"}>
        <Span classname={"header-assignment__team"}>Equipo</Span>
        <Span classname={"header-assignment__whichteam"}>{teamselected.name}</Span>
        <Image source={arrowdown} classname={"header-assignment__arrow"} onClick={handleClick} />
        <Div classname={style}>
          {teams.map((team)=>(
          <Div classname="header-assignment__item" key={team.id} onClick={(ev)=>setTeamS(team)}>
            {team.name}
          </Div>
          ))}
        </Div>
      </Div>

{/*       <Button classname={"header-assignment__button"}>
        <Span classname={"header-assignment__orderby"}>Ordernar por:</Span>
        <Span classname={"header-assignment__spanoption"}>Fecha de entrega</Span>
      </Button> */}
    </Div>
  );
};