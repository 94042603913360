import React, { useState } from "react";
import { Div } from "../Div";

export const AssignmentCalendarFooter = ({teamDayTotal,teamTotalR,teamTotalE}) => {

  return (
    <Div classname={"assigcalenfooter"}>
      <Div classname="assigcalenfooter__space"></Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[0]}</Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[1]}</Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[2]}</Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[3]}</Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[4]}</Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[5]}</Div>
      <Div classname="assigcalenfooter__total">{teamDayTotal[6]}</Div>
      <Div classname="assigcalenfooter__great-total">
        {teamTotalE.toFixed(2)}
      </Div>
      <Div classname="assigcalenfooter__space-arrow">{teamTotalR.toFixed(2)}</Div>
    </Div>
  );
};