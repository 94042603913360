import React from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Input } from "../../atoms/Input";
import { Div } from "../Div";
import rocket from "../../../images/inicio/rocket.svg";
import time from "../../../images/inicio/edit-time.svg";
import saveTime from "../../../images/inicio/save-time.svg"
import comments from "../../../images/inicio/comment.png";
import externallink from "../../../images/inicio/external-link.png";
import check from "../../../images/inicio/check.png";
import { Span } from "../../atoms/Span";
import { useState } from "react"
import axios from "axios";

export const Task = ({ task }) => {
  const baseUrl = global.backendproject;

  const [taskI, setTaskI] = useState(task)

  const [style, setStyle] = useState({div: "home-task__estimating-img", img: time, div2: "home-task__estimating-value-real",
    input: "home-task__estimating-spanrealhour"})

  const handleClick = () => {
    if(style.div==="home-task__estimating-img"){
      setStyle({div: "home-task__estimating-img2", img: saveTime, div2: "home-task__estimating-value-real2",
      input: "home-task__estimating-spanrealhour2"})
    }else{
      changeTime();
    }
  }

  const changeTime = async() => {
    let realt="";
    if(taskI.real_time[0]==="0"){ realt=taskI.real_time.slice(1) }else{ realt=taskI.real_time }
    let url = baseUrl+"/api/v1/task/assigntime?idTask="+taskI.id+"&real_time="+realt+"&estimated_time="+taskI.estimated_time;
    const response = await axios.get(url, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });
    if(response.status===204){
      setStyle({div: "home-task__estimating-img", img: time, div2: "home-task__estimating-value-real",
      input: "home-task__estimating-spanrealhour"})
      setTaskI({...taskI, real_time: realt})
    }
  }

const handleChange = (event) => {
    setTaskI({...taskI, real_time: event.target.value})
}

  return (
    <Div classname={"home-task"}>
      <Div classname={"home-task__text"}>
        <Div classname={"home-task__image-task"}>
          <Image source={rocket} alt={"task"} classname={"home-task__img"} />
        </Div>

        <Div classname={"home-task__info"}>
          <Label text={taskI.name} />
          {taskI.isToday ?
            <Label text={"hoy"} classname={"home-task__chip"} />
            :
            <Label text={taskI.dueOn.format('DD MMM')} classname={"home-task__chip"} />
          }
        </Div>
      </Div>
      <Label text={taskI.dueOn.format('DD MMM YYYY')} classname={"home-task__date"} />
      <Div classname={"home-task__estimating"}>
        <Div classname={"home-task__estimating-value"}>
          <Span classname={"home-task__estimating-spantext"}>ESTIMADO</Span>
          <Span classname={"home-task__estimating-spanhour"}>{taskI.estimated_time} H</Span>
        </Div>
        <Div classname={style.div} onClick={handleClick}> 
          <Image source={style.img} />
        </Div>
        <Div classname={style.div2}>
          <Span classname={"home-task__estimating-spanreal"}>REAL</Span>
          <Div classname={"home-task__estimating-div"}>
            <Input classname={style.input} value={taskI.real_time} onChange={(ev)=>{handleChange(ev)}} type="number" />
            <Span classname={"home-task__h-letter"}>H</Span>
          </Div>
        </Div>
      </Div>
      <Div classname={"home-task__options"}>
        <Div classname={"home-task__options-option"}>
          <Image source={comments} />
          {taskI.comments}
        </Div>
        <Div classname={"home-task__options-option"}>
          <Image source={externallink} />
        </Div>
        <Div classname={"home-task__options-option"}>
          <Image source={check} />
        </Div>
      </Div>
    </Div>
  );
};