import React from 'react'
import arrowdown from "../../../images/inicio/arrow-down.png";
import { Button } from '../../atoms/Button';
import { Image } from '../../atoms/Image';
import { Label } from '../../atoms/Label';
import { Div } from '../../molecules/Div';
import { useHistory } from "react-router-dom";

export const OptionsHomeTasks = () => {
  let history = useHistory();

  const goToTask = () =>{
    history.push('/assingment');
  }
    return (
        <Div classname={"options-inicio-tasks"}>
        <Div classname={"options-inicio-tasks__text"}>
          <Image source={arrowdown} classname={"options-inicio-tasks__image"} />
          <Label
            text={"Tareas que se deben entregar pronto"}
            classname={"options-inicio-tasks__label"}
          />
        </Div>
        <Div classname={"options-inicio-tasks__content-button"}>
          <Button classname={"options-inicio-tasks__button"} onClick={goToTask}>
            Ver todas las tareas
          </Button>
        </Div>
      </Div>
    )
}