import React from "react";
import { Button } from "../../atoms/Button";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";

export const OptionProjects = ({headers}) => {
  return (
    <Div classname={"optionproject"}>
      <Div classname={"optionproject__textarea"}>
        <Label text={headers.header1} classname={"optionproject__title"} />
        <Label
          text={headers.header2}
          classname={"optionproject__subtitle"}
        />
      </Div>
    </Div>
  );
};
