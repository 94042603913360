import React from 'react'
import { Div } from '../Div'
import { Label } from "../../atoms/Label";
import { Span } from "../../atoms/Span";
import arrowdown from "../../../images/assignment/arrow-down.svg";
import { Image } from "../../atoms/Image";
import {useState, useEffect } from "react";

export const AssignmentFormSelect = ({title, item, setItem,list}) => {
    const [style, setstyle] = useState("assignment-form-select__hide")
    const handleClick = () =>{
      if(style==="assignment-form-select__hide"){
        setstyle("assignment-form-select__show")
      }else{
        setstyle("assignment-form-select__hide")
      }
    }

    const handleSelect = (item) => {
        setstyle("assignment-form-select__hide")
        setItem(item)
    }

    return (
        <Div classname={"assignment-form-select__select"} onMouseLeave={(ev)=>setstyle("assignment-form-select__hide")}>
            <Span classname={"assignment-form-select__team"}>{title}</Span>
            <Span classname={"assignment-form-select__whichteam"}>{item.name}</Span>
            <Image source={arrowdown} classname={"assignment-form-select__arrow"} onClick={handleClick} />
            <Div classname={style}>
                {list.map((itemS) => (
                    <Div classname="assignment-form-select__item" key={itemS.id} onClick={(ev) => handleSelect(itemS)}>
                        {itemS.name}
                    </Div>
                ))}
            </Div>
        </Div>
    )
}
