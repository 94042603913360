import React from "react";
import { Div } from "../../molecules/Div";
import { FormLogin } from "../../organisms/login/FormLogin";


export const LoginPage = () => {
  return (
    <Div classname={"background-login"}>
      <FormLogin />
    </Div>
  );
};
