import React from "react";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";
import candado from "../../../images/inicio/candado.png";
import { Label } from "../../atoms/Label";
import { Span } from "../../atoms/Span";
export const Project = ({project}) => {
  return (
    <Div classname={"home-project"}>
      <Div classname={"home-project__content"}>
        <Div classname={"home-project__content-head"}>
          <Div classname={"home-project__content-head-title"}>
            Actualizado el {project.modified}
          </Div>
          <Image source={candado} />
        </Div>
        <Div classname={"home-project__content-footer"}>
          {project.user.map((usero)=>(
          <Div classname={"home-project__content-footer-item"} key={usero.name}>
            {usero.photo!=="" ? 
            <Image source={usero.photo} classname="home-project__content-footer-img">
            </Image>
            : 
            <Span>          
               {usero.name.substr(0,1)}
            </Span>}
        </Div>
          ))}
        </Div>
      </Div>
      <Div classname={"home-project__footer"}>
        <Label text={project.name} classname={"home-project__footer-label"} />
      </Div>
    </Div>
  );
};