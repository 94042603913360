import React from 'react'
import { Label } from '../../atoms/Label'
import { Div } from '../Div'

export const MenuActivity = () => {
    return (
        <Div classname={"contentdetailproject__menu-activity"}>
            <Label text={"Activity"} classname={"contentdetailproject__title"}/>
            <Label text={"Responsable"} classname={"contentdetailproject__label"}/>
            <Label text={"Fecha de inicio"} classname={"contentdetailproject__label"}/>
            <Label text={"Proyectos"} classname={"contentdetailproject__label"}/>
            <Label text={"Done"} classname={"contentdetailproject__label"}/>
            <Label text={"Descripción"} classname={"contentdetailproject__label"}/>
        </Div>
    )
}
