import React from "react";
import { Image } from "../../atoms/Image";
import { Lin } from "../../atoms/Lin";
import { Div } from "../Div";
import {useState, useEffect} from "react"

export const ItemMenu = ({ text, sourceimge, alt, path }) => {
  const[style, setStyle] = useState("menu__item-menu")
  
  useEffect(() => {
    updateStyle()
  }, [])

  const updateStyle = ()=>{
    if(text==="Inicio"&&window.location.pathname==="/home"){
      setStyle("menu__item-menu-active")
    }else if(text==="Proyectos"&&window.location.pathname==="/projects"){
      setStyle("menu__item-menu-active")
    }else if(text==="Asignaciones"&&window.location.pathname==="/assingment"){
      setStyle("menu__item-menu-active")
    }else if(text==="Usuarios"&&window.location.pathname==="/users"){
      setStyle("menu__item-menu-active")
    }
  }

  return (
      <Lin path={path} className={style}>
        <Image source={sourceimge} alt={alt} classname={"menu__image-item"} />
        {text}
      </Lin>

  );
};
