import React from "react";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";

import titamedia from "../../../images/inicio/titamedia.svg";
import hamburguer from "../../../images/inicio/hamburguer.png";

export const LogoMenu = () => {
  return (
    <Div classname={"menu__menu-logo"}>
      <Image source={titamedia} alt={"logo"} classname={"menu__image"} />
    </Div>
  );
};
