import React from "react";
import { Div } from "../Div";
import arrowleft from '../../../images/assignment/arrow-left-black.svg';
import arrowright from '../../../images/assignment/arrow-right-black.svg';
import { Label } from "../../atoms/Label";
import { Image } from "../../atoms/Image";
export const AssignmentCalendarHeader = ({weekLess, weekPlus,week}) => {
  return (
    <Div classname="assigncalenheader">
      <Div classname="assigncalenheader__members">
        Miembros
      </Div>
      <Div classname="assigncalenheader__use">
        
      </Div>
      <Div classname="assigncalenheader__space"></Div>
      <Div classname="assigncalenheader__space_change"></Div>
      <Div classname="assigncalenheader__space_change"></Div>
      <Div classname="assigncalenheader__space"></Div>
      <Div classname="assigncalenheader__arrow">
        <Div classname="assigncalenheader__arrow-arrow" onClick={weekLess}>
          <Image source={arrowleft} />
        </Div>
      </Div>
      <Div classname="assigncalenheader__day">
        <Label text={"L"} />
        <Label text={week[0].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__day">
        <Label text={"M"} />
        <Label text={week[1].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__day">
        <Label text={"M"} />
        <Label text={week[2].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__day">
        <Label text={"J"} />
        <Label text={week[3].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__day">
        <Label text={"V"} />
        <Label text={week[4].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__day_end">
        <Label text={"S"} />
        <Label text={week[5].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__day_end">
        <Label text={"D"} />
        <Label text={week[6].substring(8,10)} />
      </Div>
      <Div classname="assigncalenheader__sum">SUM</Div>
      <Div classname="assigncalenheader__arrow">
        <Div classname="assigncalenheader__arrow-arrow" onClick={weekPlus}>
          <Image source={arrowright} />
        </Div>
      </Div>
    </Div>
  );
};
