import React, { useState } from "react";

import responsable from "../../../images/detailproject/imageperfil.png";
import { Div } from "../Div";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { FooterTabbleShedulling } from "./FooterTabbleShedulling";

import exchange from "../../../images/detailproject/exchange.png";
import settings from "../../../images/detailproject/settings.png";
import { useSelector } from "react-redux";
import { Input } from "../../atoms/Input";
import axios from "axios";

const baseSearch = global.backenduser;
export const BodyTableShedulling = ({
  shedulling,
  assignUser,
  changeShedulling,
  numweek,
}) => {
  const auth = useSelector((state) => state.auth);
  const [users, setUsers] = useState([]);
  const searchUser = async (e) => {
    assignUser({ name: e.target.value, email: "", idasana: 0 });
    if (e.target.value.length > 3) {
      let { data } = await axios.get(
        `${baseSearch}/api/v1/user/search/${e.target.value}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
      setUsers(data);
    }
  };

  const selectUser = (user) => {
    assignUser({ name: user.name, email: user.email, idasana: user.asanaId });
    setUsers([]);
  };

  return (
    <Div classname="bodycalendarmain">
      <Div classname="bodycalendar">
        <Div classname="bodycalendar__calendar">
          <Input
            value={shedulling.user.name}
            classname="bodycalendar__input"
            onChange={searchUser}
          />
          {users.length > 0 && (
            <Div classname="bodycalendar__users">
              {users.map((user, index) => (
                <Div
                  key={index}
                  classname="bodycalendar__user"
                  onClick={() => selectUser(user)}
                >
                  {user.name}
                </Div>
              ))}
            </Div>
          )}
        </Div>
        <Div classname="bodycalendar__calendar-img">
          <Image source={exchange} />
        </Div>
        <Div classname="bodycalendar__calendar-img">
          <Image source={settings} />
        </Div>
        <Div classname="bodycalendar__arrow"></Div>
        {numweek === 1 ? (
          <>
            {shedulling.shedulle.map((hour, index) => (
              <Div
                key={index}
                classname="bodycalendar__day bodycalendar__day_active"
              >
                <Input
                  classname="bodycalendar__input-hours"
                  value={
                    hour.estimated_time === null ? "" : hour.estimated_time
                  }
                  onChange={(e) => changeShedulling(e.target.value, index, 1)}
                />
              </Div>
            ))}
          </>
        ) : (
          <>
          {shedulling.shedulletwo.map((hour, index) => (
            <Div
              key={index}
              classname="bodycalendar__day bodycalendar__day_active"
            >
              <Input
                classname="bodycalendar__input-hours"
                value={
                  hour.estimated_time === null ? "" : hour.estimated_time
                }
                onChange={(e) => changeShedulling(e.target.value, index, 2)}
              />
            </Div>
          ))}
        </>
        )}

        <Div classname="bodycalendar__day"></Div>
        <Div classname="bodycalendar__day"></Div>
        <Div classname="bodycalendar__sum bodycalendar__day_active">
          {shedulling.totalHours}
        </Div>
        <Div classname="bodycalendar__arrow"></Div>
      </Div>

      <FooterTabbleShedulling shedulling={shedulling} numweek={numweek} />
    </Div>
  );
};
