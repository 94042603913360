import React, { useState } from 'react'
import { Image } from '../../atoms/Image'
import { Label } from '../../atoms/Label'
import { Div } from '../Div'
import arrowdown from '../../../images/detailproject/arrow-down.png';
import menupoints from '../../../images/detailproject/menupoints.png';
import addcircle from '../../../images/detailproject/addcircle.png'
import { AddTask } from './AddTask';

export const OptionsSprint = ({title,id, newTask}) => {
    const [task, setTask] = useState(false);

    const addNewTask = (form) => {
      if (
        form.task.length > 0 &&
        form.responsable.email.length > 0 &&
        form.date[0].startDate !== form.date[0].endDate
      ) {
    
        newTask(id, form);
        setTask(!task);
      }
    };
  
    const closeAddTask = () => {
      setTask(!task);
    }
    return (
        <Div classname={"optionssprint"}>
            <Div classname={"optionssprint__area"}>
                <Div classname={"optionssprint__arrowtext"}>
                    <Image source={arrowdown} classname={"optionssprint__arrow"}/>
                    <Label text={title} classname={"optionssprint__text"}/>
                </Div>
                <Div classname={"optionssprint__addmenu"}>
                    <Image source={addcircle} classname={"optionssprint__add"} onClick={() => setTask(!task)}/>
                    <Image source={menupoints}/>
                </Div>
            </Div>
            {task && <AddTask  addNewTask={addNewTask} closeAddTask={closeAddTask}/> }
        </Div>
    )
}
