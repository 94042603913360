import React, { useEffect, useState } from "react";
import { Div } from '../Div'
import { Label } from "../../atoms/Label";
import { stringify } from "uuid";

export const AssignmentCalendarBodyDay = ({ item }) => {

    const format = (a) =>{
        let array = a.toString().split(".");
        if(array[1]===undefined){
            return array[0]
        }else{
            let n = array[0]+'.'+array[1].substring(0,2)
            return n
        }
    }
    return (
        <Div classname="assigncalenbody__day">
            {item.estimatedh < 6 && (
                <Label text={format(item.estimatedh)} classname="assigncalenbody__est" />
            )}
            {item.estimatedh >= 6 && item.estimatedh <= 9 && (
                <Label text={format(item.estimatedh)} classname="assigncalenbody__good" />
            )}
            {item.estimatedh > 9 && (
                <Label text={format(item.estimatedh)} classname="assigncalenbody__danger" />
            )}
            <Label text={format(item.realh)} classname="assigncalenbody__real" />
        </Div>
    )
}
