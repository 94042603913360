import React from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import check from "../../../images/detailproject/check.png";

export const TaskBoard = ({ task, selectTask }) => {
  return (
    <Div classname="taskboard" onClick={() => selectTask(task)}>
      <Div classname="taskboard__header">
        <Image source={check} classname="taskboard__header-img" />
        <Label text={task.name} classname="taskboard__header-label" />
      </Div>
      <Div classname="taskboard__body">
        {task?.tags.map((tag) => (
          <Div classname="taskboard__body-color">{tag.name}</Div>
        ))}
      </Div>
      <Div classname="taskboard__footer">
        {task.user.photo !== null ? (
          task.user.photo !== "" ? (
            <>
              <Image
                source={task.user.photo}
                classname={"rowprojectdetail__responsable-img"}
              />
              {task.user.name}
            </>
          ) : (
            <>
              <Div classname="rowprojectdetail__responsable-letter">
                {task.user.name.substring(0, 1).toUpperCase()}
              </Div>
              {task.user.name}
            </>
          )
        ) : task.user.name !== null ? (
          <Div classname="rowprojectdetail__responsable-letter">
            {task.user?.name.substring(0, 1).toUpperCase()}
          </Div>
        ) : null}
      </Div>
    </Div>
  );
};
