import React, { useState } from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import check from "../../../images/detailproject/check.png";
import comment from "../../../images/detailproject/coment.png";

import { DateRange } from "react-date-range";
import { horaMesCalendar } from "../../../helpers/horaMes";

export const RowProjectDetail = ({ task, selectTask }) => {
  const [dateLabel, setDateLabel] = useState({
    startDate: task.start_on == null ? null : horaMesCalendar(task.start_on),
    endDate: task.due_on == null ? null : horaMesCalendar(task.due_on),
  });

  const [date, setDate] = useState([
    {
      startDate:
        task.start_on == null
          ? task.due_on == null
            ? new Date()
            : new Date(task.due_on)
          : new Date(task.start_on),
      endDate: task.due_on == null ? new Date() : new Date(task.due_on),
      key: "selection",
    },
  ]);
  const [state, setstate] = useState(false);

  const changeDate = (item) => {
    setDate([item.selection]);
    if (item.selection.endDate !== item.selection.startDate) {
      setDateLabel({
        startDate: horaMesCalendar(item.selection.startDate),
        endDate: horaMesCalendar(item.selection.endDate),
      });
      setstate(!state);
    }
  };

  return (
    <Div classname={"rowprojectdetail"}>
      <Div classname={"rowprojectdetail__areatext"}>
        <Div classname={"rowprojectdetail__check-task"}>
          <Image source={check} classname={"rowprojectdetail__check"} />
          <Label
            text={task.name}
            classname={"rowprojectdetail__task"}
            onClick={() => selectTask(task)}
          />
        </Div>
        <Div classname={"rowprojectdetail__comment-numeric"}>
          <Image source={comment} classname={"rowprojectdetail__comment"} />
          <Label text={task.storys} classname={"rowprojectdetail__numeric"} />
        </Div>
      </Div>
      <Div classname={"rowprojectdetail__areatext"}>
        <Div classname={"rowprojectdetail__responsable"}>
          {task.user.photo !== "" ? (
            <Image
              source={task.user.photo}
              classname={"rowprojectdetail__responsable-img"}
            />
          ) : (
            <Div classname="rowprojectdetail__responsable-letter">
              {task.user.name.substring(0, 1).toUpperCase()}
            </Div>
          )}

          <Label
            text={task.user.name}
            classname={"rowprojectdetail__responsable-name"}
          />
        </Div>

        <Div classname={"rowprojectdetail__date"}>
          <Label
            text={
              dateLabel.startDate == null && dateLabel.endDate != null
                ? dateLabel.endDate
                : dateLabel.startDate != null && dateLabel.endDate != null
                ? `${dateLabel.startDate} - ${dateLabel.endDate}`
                : "Sin asignar"
            }
            onClick={() => setstate(!state)}
            classname={"rowprojectdetail__date-label"}
          />
          {state ? (
            <Div
              classname={"rowprojectdetail__date-range"}
              onMouseLeave={() => {
                setstate(!state);
              }}
            >
              <DateRange
                editableDateInputs={false}
                onChange={(item) => changeDate(item)}
                moveRangeOnFirstSelection={false}
                ranges={date}
                rangeColors={["#80E593"]}
              />
            </Div>
          ) : null}
        </Div>

        <Div classname={"rowprojectdetail__percentage"}>
          <Label
            text={"100%"}
            classname={"rowprojectdetail__percentage-value"}
          />
        </Div>
      </Div>
    </Div>
  );
};
