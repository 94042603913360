import React from "react";
import { ListMenu } from "../components/organisms/listmenu/ListMenu";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { HomePage } from "../components/pages/home/HomePage";
import { Div } from "../components/molecules/Div";
import { Header } from "../components/organisms/header/Header";
import { Projects } from "../components/pages/projects/Projects";
import { DetailProject } from "../components/pages/detailproject/DetailProject";
import { Assignment } from "../components/pages/assignments/Assignment";
import { Users } from "../components/pages/Users/Users";
import { Notification } from "../components/organisms/home/Notification";

export const ProyectoXRouters = () => {
  
  return (
    <>
          <Div classname={"main-content"}>
            <ListMenu />
            <Div classname={"content"}>
            
              <Header />
              <Notification/>
              <Switch>
                <Route exact path="/home" component={HomePage} />
                <Route exact path="/projects" component={Projects} />
                <Route exact path="/detailproject/:idproject" component={DetailProject} />
                <Route exact path="/assingment" component={Assignment} />
                <Route exact path="/users" component={Users} />
                <Redirect to="/home" />
              </Switch>
            </Div>
          </Div>
    </>
  );
};