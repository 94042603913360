import React from "react";
import { Button } from "../../atoms/Button";
import { Div } from "../Div";

export const FilterOptions = ({selectFilter, options,setOptions}) => {
  return (
    <Div classname="filteroptions" onMouseLeave={() =>setOptions(!options) }>
      <Button classname="filteroptions__option" onClick={() =>  selectFilter("incomplete")}>Tareas sin finalizar</Button>
      <Button classname="filteroptions__option" onClick={() =>  selectFilter("completed")}>Tareas finalizadas</Button>
      <Button classname="filteroptions__option" onClick={() =>  selectFilter("all")}>Todas las tareas</Button>
    </Div>
  );
};
