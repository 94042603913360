import React, { useEffect, useState } from "react";
import { HeaderUsers } from "../../molecules/users/HeaderUsers";
import { FormUsers } from "../../organisms/users/FormUsers";
import { TableUsers } from "../../organisms/users/TableUsers";
import axios from "axios";
import { Div } from "../../molecules/Div";

const baseUrl = global.backenduser;

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [senorityList, setSenorityList] = useState([]);
  const [form, setForm] = useState({
    names: "",
    email: "",
    perfil: "",
    team: "",
    accessToken: "",
    seniority : "",
    valueHour : 0
  });
  const [stext, setStext] = useState("El usuario ha sido eliminado exitosamente.")
  const [ftext, setFtext] = useState("Hubo un problema al momento de eliminar el usuario.")
  const [sClass, setSclass] = useState("display-none")
  const [fClass, setFclass] = useState("display-none")
  useEffect(() => {
    loadUsers();
    loadSenorityList();
  }, []);

  const loadUsers = async () => {
    const { data } = await axios.get(`${baseUrl}/api/v1/user/findallactive`, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });
    setUsers(data);
  };

  const loadSenorityList = async () => {
    const { data } = await axios.get(`${baseUrl}/api/v1/seniority/findall`, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });
    setSenorityList(data);
  };

  const handleForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const choosePerfil = (perfil) => {
    switch (perfil) {
      case "ADMIN":
        setForm({
          ...form,
          perfil: 1,
        });
        break;
      case "ECOMMERCE_MANAGER":
        setForm({
          ...form,
          perfil: 2,
        });
        break;
      case "LEADER":
        setForm({
          ...form,
          perfil: 3,
        });
        break;
      case "REGULAR":
        setForm({
          ...form,
          perfil: 4,
        });
        break;

      default:
        break;
    }
  };

  const chooseTeam = (team) => {
    switch (team) {
      case "FRONTEND":
        setForm({
          ...form,
          team: 1,
        });
        break;
      case "BACKEND":
        setForm({
          ...form,
          team: 2,
        });
        break;
      case "DISEÑO":
        setForm({
          ...form,
          team: 3,
        });
        break;
      case "PAUTA":
        setForm({
          ...form,
          team: 4,
        });
        break;
      case "ECOMMERCE_MANAGER":
        setForm({
          ...form,
          team: 5,
        });
        break;
      case "SEO":
        setForm({
          ...form,
          team: 6,
        });
        break;
      case "DEVOPS":
        setForm({
          ...form,
          team: 7,
        });
        break;
      case "COMERCIAL":
        setForm({
          ...form,
          team: 8,
        });
      case "PROCESOS&QA":
        setForm({
          ...form,
          team: 10,
        });
        break;
      default:
        break;
    }
  };

  const chooseSenority = (seniority) => {
    let idSeniority=0;
    switch (seniority) {
      case "JUNIOR":
        idSeniority=1;
      break;
      case "SENIOR":
        idSeniority=3;
      break;
      case "EXPERTO":
        idSeniority=4;
      break;
      case "NIVEL MEDIO":
        idSeniority=2;
      break;
      default:
        break;
    }
    setForm({
      ...form,
      seniority: idSeniority,
      valueHour: getValueHourForSeniority(idSeniority)
    });
  };

  const getValueHourForSeniority = (idSeniority) => {
    let valueHour = 0;
    let senorityFound = senorityList.filter(x=>x.seniority==idSeniority);
    if(senorityFound.length>0){
      valueHour=senorityFound[0].feed;
    }
    return valueHour;
  }

  const saveNewUser = async () => {
    let request = {
      email: form.email,
      name: form.names,
      accessToken: form.accessToken,
      rolId: form.perfil,
      groupId: form.team,
      seniority: form.seniority
    };
    const { data } = await axios.post(`${baseUrl}/api/v1/user/saveWithToken`, request, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });

    setForm({
      names: "",
      email: "",
      accessToken: "",
      perfil: "",
      team: "",
      seniority: "",
      valueHour: 0,
    });
  };

  const selectUserToEdit = (user) => {
    console.log(user);
    setForm({
      names: user.name,
      email: user.email,
      perfil: user.rolId,
      team: user.groupId,
      seniority: parseInt(user.seniority),
      valueHour: getValueHourForSeniority(user.seniority),
    });
  };

  const editUser = async () => {
    let request = {
      email: form.email,
      name: form.names,
      rolId: form.perfil,
      groupId: form.team,
      accessToken: form.accessToken,
      seniority: form.seniority
    };
    const { data } = await axios.put(`${baseUrl}/api/v1/user/updateWithToken`, request, {
      headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
    });

    setForm({
      names: "",
      email: "",
      perfil: "",
      team: "",
      accessToken: "",
      seniority: "",
      valueHour: 0,
    });
    loadUsers();
  };


  const inacUser = async (email) => {
    try {
      const data = await axios.delete(baseUrl + `/api/v1/user/inactivate/` + email, {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      });
      if (data.status === 204) {
        loadUsers()
        setSclass("user-sucess-text")
        setTimeout(() => setSclass("display-none"), 3000)
      } else {
        setFclass("user-fail-text")
        setTimeout(() => setFclass("display-none"), 3000)
      }
    } catch (error) {
      setFclass("user-fail-text")
      setTimeout(() => setFclass("display-none"), 3000)
    }
  }



  return (
    <>
      <HeaderUsers saveNewUser={saveNewUser} />
      <FormUsers
        handleForm={handleForm}
        chooseTeam={chooseTeam}
        choosePerfil={choosePerfil}
        chooseSenority={chooseSenority}
        form={form}
      />
      <TableUsers
        users={users}
        selectUserToEdit={selectUserToEdit}
        editUser={editUser}
        inacUser={inacUser}
      />
      <Div classname={sClass}>
        {stext}
      </Div>
      <Div classname={fClass}>
        {ftext}
      </Div>
    </>
  );
};
