import moment from "moment";
import "moment/locale/es";

export const horaMes = (fecha) => {
  const hoyMes = moment(fecha);

  return hoyMes.format("HH:mm a | MMMM Do");
};

export const horaMesCalendar = (fecha) => {
  const hoyMes = moment(fecha);

  return hoyMes.format("l");
};

export const getNameDayOfWeek = (day) => {
  return moment().day(day).format("dddd");
};

export const getNumberDayOfWeek = (day) => {
  return moment().day(day).format("D");
};

export const getDate = (day) => {
  
  return moment(day).format("l");
};

export const getDateToRequest = (day) => {
  let date = (moment().day(day).format("L") + "")
    .split("/")
    .reverse()
    .join("/");

  return date;
};

export const formatDateOfResponse = (date) => {
  return moment(date).day();
};

export const formatDateRequest = (date) => {
  return moment().day(date).format();
};
export const formatDateCreateTask = (date) => {
  let dates = moment(date);
  return dates.format("L").toString().split("/").reverse().join("-");


};
