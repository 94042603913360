import React from 'react'
import { Div } from '../Div'

export const Page = ({page, loadProject}) => {

    return (
        <Div classname={"paginador__page"} onClick={() => loadProject(page)}>
            {parseInt(page  )}
        </Div>
    )
}
