import React from 'react'
import { Div } from '../Div'

export const HeaderTableUsers = () => {
    return (
        <Div classname="headertableusers">
            <Div classname="headertableusers__head">
                Miembros
            </Div>
            <Div classname="headertableusers__head">
                Correo
            </Div>
            <Div classname="headertableusers__head">
                Perfil
            </Div>
            <Div classname="headertableusers__head">
                Equipo
            </Div>
        </Div>
    )
}
