import React from 'react'
import {  AssignmentHeader} from '../../molecules/assignments/AssignmentHeader';
import { AssignmentTable } from '../../organisms/assignment/AssignmentTable';
import {useState, useEffect } from "react";
import axios from "axios";

const baseUrl = global.backenduser;

export const Assignment = () => {
    const [teamselected, setTeamSelected] = useState({name:""})
    const [allUsers, setAllUsers] = useState([])
    
    useEffect(() => {
        getAllUsers();
    }, [])

    const getAllUsers = async() =>{
        const { data } = await axios.get(`${baseUrl}/api/v1/user/findallactive`, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
          });
          setAllUsers(data);
    }

    return (
     <>
        <AssignmentHeader teamselected={teamselected} setTeamSelected={setTeamSelected}/>
        <AssignmentTable teamselected={teamselected} allUsers={allUsers}/>
     </>
    )
}