import axios from "axios";
import React, { useEffect, useState } from "react";
import { Div } from "../../molecules/Div";
import { ComentsProject } from "../../molecules/showDetailProject/ComentsProject";
import { DescriptionProject } from "../../molecules/showDetailProject/DescriptionProject";
import { HeaderActivity } from "../../molecules/showDetailProject/HeaderActivity";
import { MenuActivity } from "../../molecules/showDetailProject/MenuActivity";
import { ResourcesDetailProject } from "../../molecules/showDetailProject/ResourcesDetailProject";
import { Shedulling } from "../../molecules/showDetailProject/Shedulling";

const baseUrl = global.backendproject;
export const ContentShowDetailProject = ({
  actishedulling,
  weeks,
  numweek,
  changeCalendar,
  shedulling,
  assignUser,
  changeShedulling,
  addNewResourceLocal,
  idtask,
  task,
}) => {
  return (
    <Div classname={"contentdetailproject"}>
      {actishedulling ? (
        <>
          <MenuActivity />
          <Div classname={"contentdetailproject__main-content"}>
            <HeaderActivity task={task} />
            <DescriptionProject />
            <ResourcesDetailProject
              attachments={task}
              addNewResourceLocal={addNewResourceLocal}
            />
            <ComentsProject stories={task.stories} />
          </Div>
        </>
      ) : null}
      {actishedulling === false ? (
        <Shedulling
          weeks={weeks}
          numweek={numweek}
          changeCalendar={changeCalendar}
          shedulling={shedulling}
          assignUser={assignUser}
          changeShedulling={changeShedulling}
        />
      ) : null}
    </Div>
  );
};
