import React from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import coment from "../../../images/detailproject/imgcoment.png";
import { Span } from "../../atoms/Span";
import { getDate } from "../../../helpers/horaMes";
export const ComentsProject = ({ stories }) => {
  console.log(stories);

  return (
    <Div classname={"comentsproject"}>
      {stories.map((storie) => (
        <Div classname={"comentsproject__responsable"} key={storie.id}>
          <Div classname={"comentsproject__responsable-img"}>
            {storie.createdBy.photo ? (
              <Image source={coment} />
            ) : (
              <Div classname="comentsproject__responsable-letter">{storie.createdBy.name.substr(0, 1).toUpperCase()}</Div>
            )}
          </Div>
          <Div classname={"comentsproject__responsable-coment"}>
            <Div classname={"comentsproject__responsable-coment-title"}>
              <Label
                text={storie.createdBy.name}
                classname={"comentsproject__responsable-label"}
              />
              <Span classname={"comentsproject__responsable-span"}>
                {getDate(storie.createdAt)}
              </Span>
            </Div>
            <Div classname={"comentsproject__responsable-coment-text"}>
              {
                storie.resourceSubtype === "attachment_added" ? (
                    <Div classname="comentsproject__file">
                        {storie.attachmentName}
                    </Div>
                ) : (
                  <>
                 
                  {storie.text}
                 
                  
                  </>
                )
              }
              
            </Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
};
