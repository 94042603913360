import { types } from "../types/types";

export const saveUser = (credentials) => ({
  type: types.login,
  payload: {
    name: credentials.name,
    email: credentials.email,
    logged: true,
    roleId:credentials.roleId,
    role:credentials.role,
    groupId:credentials.groupId,
    group:credentials.group,
    img:credentials.img
  },
});

export const logoutUser = () => ({
    type: types.logout,
    payload: {
        name: "",
        email: "",
        logged: false,
        roleId:"",
        role:"",
        groupId:"",
        group:"",
        img:"",
      },
  });