import React from 'react'
import { Div } from '../../molecules/Div'
import { Project } from '../../molecules/inicio/Project'
import { useState,useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';
import { useSelector, useDispatch } from "react-redux";



export const Projects = () => {
    const baseUrl = global.backendproject;
    const user = useSelector(state => state.auth)

    const [projects, setProjects] = useState([]);

    
    useEffect(() => {
        if(projects.length===0){
            getProjects();
        }
    }, [])

    const getProjects = async() =>{
        const { data } = await axios.get(baseUrl+`/api/v1/project/getprojectmodel?email=`+user.email+`&page=1&maxPage=6&sort=ASC`, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
          });
        formatData(data.content);
    }

    const formatData = (data) => {
        for (let i = 0; i<data.length; i++){
            let nextd = moment(data[i].modified.slice(0,10))
            let nextdd= nextd.format("DD MMM");
            data[i].modified=nextdd
            let users = [];
            for(let j = 0; j<data[i].user.length;j++){
                if(data[i].user[j].name!==null){
                    users.push(data[i].user[j])
                }
            }
            data[i].user=users;
        }
        setProjects(data)
    }

    return (
        <Div classname={"projects"}>
            {projects.map((project) => (
                <Project project={project} key={project.id}/>
            ))}
        </Div>
    )
}
