import React from 'react'
import { Image } from '../../atoms/Image'
import { Div } from '../Div';
import { Label } from '../../atoms/Label';
import { useSelector, useDispatch } from "react-redux";
import { Button } from '../../atoms/Button';
import { logoutUser } from "../../../actions/auth";


export const InfoUser = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth)
    
    const logout = () =>{
        localStorage.setItem('pxlogged', false);
        dispatch(logoutUser())
    }

    return (
        <Div classname={"header__second"}>
            <Div classname={"header__image-user"}>
                <Image source={user.img} alt={"Image-User"} classname={"header__img-user"} />
            </Div>
            <Div classname={"header__name-user"}>
                <Label classname={"header__label-name"} text={user.name}/>
                <Label  classname={"header__label-job"} text={user.role+" "+user.group}/>
            </Div>
            <Div classname={"header__logout"}>
                <Button classname={"header__logout-button"} onClick={logout}>
                </Button>
            </Div>
        </Div>
    )
}
