import React from 'react'
import { Div } from "../Div";
import { Label } from "../../atoms/Label";
import { AssignmentFormInput } from './AssignmentFormInput';
import { useState,useEffect } from 'react';
import { AssignmentFormSelect } from './AssignmentFormSelect';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import { AssignmentFormProjectSelect } from './AssignmentFormProjectSelect';

export const AssignmentCalendarTaskForm = ({ days, changeScheduleForm, allProjects, project,
    section, setSection, sectionList, taskName, setTaskName, selectProject, formWeekSchedule,
    cancelFunction,loader }) => {

    const [projectList, setProjectList] = useState([])

    useEffect(() => {
        if (allProjects.length>0){
            setProjectList(allProjects)
        }
    }, [allProjects])

    const filterProject = (a) =>{
        let ap = (JSON.parse(JSON.stringify(allProjects)));
        let np = [];
        if(a===""){
            setProjectList(ap)
        }else{
            for(let i = 0; i< ap.length;i++){
                if(ap[i].name.toLowerCase().includes(a.toLowerCase())){
                    np.push(ap[i])
                }
            }
            setProjectList(np)
        }
    }

    const getCalendarTask = (i,j) => {
        let te = returnTE(i);
        if(j<6){
            return (
                <AssignmentFormInput key={i.date} date={i.date}
                    changeScheduleForm={changeScheduleForm}
                    initialValueE={te} disabledE={false} hoursDay={i.estimatedh}></AssignmentFormInput>
            )
        }else{
            return (
                <AssignmentFormInput key={i.date} date={i.date}
                    changeScheduleForm={changeScheduleForm}
                    initialValueE={te} disabledE={true} hoursDay={i.estimatedh}></AssignmentFormInput>
            )
        }
    }

    const returnTE = (i) => {
        for (let j = 0; j < formWeekSchedule.length; j++) {
            if (formWeekSchedule[j].scheduleDate.substring(0, 10) === i.date) {
                return formWeekSchedule[j].estimated_time;
            }
        }
    }

    return (
        <Div classname="assigncalentask">
            <Div classname="assigncalentask__loader" hidden={loader}>
                <Loader type="Oval" color="#80E593" secondaryColor="Grey" height={65} width={65} />
            </Div>
            <Div classname="assigncalentask__form">
                <AssignmentFormProjectSelect title={"Proyecto:"} item={project}
                    setItem={selectProject} list={projectList} filterProject={filterProject}></AssignmentFormProjectSelect>
                <AssignmentFormSelect title={"Sección:"} item={section}
                    setItem={setSection} list={sectionList}></AssignmentFormSelect>
                <Div classname="assigncalentask__form-input-space">
                    <Label text={"Nombre:"} classname="assigncalentask__form-label">
                    </Label>
                    <Input placeholder={"Nombre de la tarea"} classname="assigncalentask__form-input"
                        onChange={(ev) => setTaskName(ev.target.value)} value={taskName}></Input>
                </Div>
                <Div classname="assigncalentask__cancel-space">
                    <Button classname="assigncalentask__cancel-button" onClick={cancelFunction}>Cancelar</Button>
                </Div>
            </Div>
            <Div classname="assigncalentask__arrow">
                <Label text={"EST"} classname="assigncalentask__arrow-label" />
                <Label text={"REAL"} classname="assigncalentask__arrow-label" />
            </Div>
            {days.map((day,index) => (
                getCalendarTask(day,index)
            ))}
            <Div classname="assigncalentask__sum"></Div>
            <Div classname="assigncalentask__sum-total"></Div>
        </Div>
    )
}
