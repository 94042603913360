import React, { useState } from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../../molecules/Div";
import arrowdown from "../../../images/detailproject/arrow-down.png";

export const ListProfileTeam = ({ label, lists, select, textSelected }) => {
  const [list, setList] = useState(false);

  const selectCategory = (item) => {
    select(item);
    setList(!list);
  };
  return (
    <Div classname="listprofileteam">
      <Label text={label} classname="listprofileteam__label" />
      <Div classname="listprofileteam__input">
        {label === "Perfil"
          ? textSelected === ""
            ? "Seleccione"
            : textSelected === 1
            ? "ADMIN"
            : textSelected === 2
            ? "ECOMMERCE_MANAGER"
            : textSelected === 3
            ? "LEADER"
            : textSelected === 4
            ? "REGULAR"
            : null
          : null}
        {label === "Equipo"
          ? textSelected === ""
            ? "Seleccione"
            : textSelected === 1
            ? "FRONTEND"
            : textSelected === 2
            ? "BACKEND"
            : textSelected === 3
            ? "DISEÑO"
            : textSelected === 4
            ? "PAUTA"
            : textSelected === 5
            ? "ECOMMERCE_MANAGER"
            : textSelected === 6
            ? "SEO"
            : textSelected === 7
            ? "DEVOPS"
            : textSelected === 8
            ? "COMERCIAL"
            : textSelected === 10
            ? "PROCESOS&QA"
            :null
          : null}
        {
        label === "Seniority"
          ? textSelected === ""
            ? "Seleccione"
            : textSelected === 1
            ? "JUNIOR"
            : textSelected === 2
            ? "NIVEL MEDIO"
            : textSelected === 3
            ? "SENIOR"
            : textSelected === 4
            ? "EXPERTO"
            : null
          : null}          
        <Image
          source={arrowdown}
          classname="listprofileteam__img"
          onClick={() => setList(!list)}
        />
      </Div>
      {list ? (
        <Div classname={"listprofileteam__lists"}>
          {lists.map((item, index) => (
            <Div
              key={index}
              classname="listprofileteam__item"
              onClick={() => selectCategory(item)}
            >
              {item}
            </Div>
          ))}
        </Div>
      ) : null}
    </Div>
  );
};
