import React from 'react'
import calendar from "../../../images/detailproject/calendar.png";
import arrowleft from "../../../images/detailproject/arrow-left.png";
import arrowright from "../../../images/detailproject/arrow-right.png";
import { Div } from '../Div';
import { Image } from '../../atoms/Image';
import { Label } from '../../atoms/Label';
import { getDate } from '../../../helpers/horaMes';

export const HeaderTableShedulling = ({ weeks, numweek, changeCalendar }) => {
  const goCalendar = (option) => {
    if (option !== numweek) {
      changeCalendar(option);
    }
  }
  return (
    <Div classname="headercalendar">
      <Div classname="headercalendar__calendar">
        {numweek === 1 ? (`${getDate(1)} - ${getDate(7)}`) : (`${getDate(8)} - ${getDate(14)}`)}
      </Div>
      <Div classname="headercalendar__calendar-img">
        <Image source={calendar} />
      </Div>
      <Div classname="headercalendar__space"></Div>
      <Div classname="headercalendar__arrow">
        <Div classname="headercalendar__arrow-arrow" onClick={() => goCalendar(1)}>
          <Image source={arrowleft} />
        </Div>
      </Div>
      {
        weeks.map((day, index) => {
          if (index === 5 || index === 6) {
            return (
              <Div key={index} classname="headercalendar__day_end">
                <Label text={day.dayName.toUpperCase()[0]} />
                <Label text={day.dayNumber} />
              </Div>
            )

          } else {
            return (
              <Div key={index} classname="headercalendar__day">
                <Label text={day.dayName.toUpperCase()[0]} />
                <Label text={day.dayNumber} />
              </Div>
            )
          }

        })
      }

      <Div classname="headercalendar__sum">SUM</Div>
      <Div classname="headercalendar__arrow">
        <Div classname="headercalendar__arrow-arrow" onClick={() => goCalendar(2)}>
          <Image source={arrowright} />
        </Div>
      </Div>
    </Div>
  )
}
