import React from 'react'
import { Div } from '../Div';

export const FooterTabbleShedulling = ({shedulling, numweek}) => {
    return (
        <Div classname={"footercalendar"}>
        <Div classname="footercalendar__space"></Div>
        {
          numweek === 1 ? (
            <>
            {shedulling.shedulle.map((hour, index) => (
              <Div key={index} classname="footercalendar__total">{hour.estimated_time}</Div>
            ))}
            </>
          )
          :(
            <>
            {shedulling.shedulletwo.map((hour, index) => (
              <Div key={index} classname="footercalendar__total">{hour.estimated_time}</Div>
            ))}
            </>
          )
        }
       
        
       
        <Div classname="footercalendar__total"></Div>
        <Div classname="footercalendar__total"></Div>
        <Div classname="footercalendar__great-total">{shedulling.totalHours}</Div>
        <Div classname="footercalendar__space-arrow"></Div>
      </Div>
    )
}
