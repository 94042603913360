import React from "react";
import { Div } from "../Div";
import arrowdown from '../../../images/inicio/arrow-down.png';
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";

export const OptionProject = () => {
  return (
    <Div classname={"option-project"}>
        <Image source={arrowdown} classname={"option-project__image"} />
        <Label
          text={"Proyectos en los que participo"}
          classname={"option-project__label"}
        />
    </Div>
  );
};
