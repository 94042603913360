import React from 'react'
import { Input } from '../../atoms/Input'
import { Label } from '../../atoms/Label'
import { Div } from '../Div'

export const InputUser = ({label , placeholder,handleForm, names, value, disabled}) => {
    return (
        <Div classname="inputuser">
            <Label text={label} classname="inputuser__label"/>
            <Input placeholder={placeholder} classname="inputuser__input" name={names} onChange={handleForm} value={value} disabled={disabled} />
        </Div>
    )
}
