import React from "react";

import { LoginPage } from "../components/pages/login/LoginPage";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { ProyectoXRouters } from "./ProyectoXRouters";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { useSelector } from "react-redux";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { saveUser } from "../actions/auth";
import { useEffect } from "react";
import { logoutUser } from "../actions/auth";

export const AppRouter = () => {
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch();

  useEffect(() => {
    checkLogin()
  }, [])

  const checkLogin = () => {
    let sessionTimeout = localStorage.getItem("pxsessionTimeout");
    let user = localStorage.getItem("pxuser");
    let login = localStorage.getItem("pxlogged");
    let sessionTimeoutMoment = moment(sessionTimeout, "DD/MM/YYYY, h:mm:ss a")
    let loadMoment = moment();
    if (login !== undefined && login !== null && sessionTimeout !== undefined && sessionTimeout !== null &&
      user !== undefined && user !== null) {
      if (sessionTimeoutMoment > loadMoment && !(login === "false" || login === false)) {
        let credentials = JSON.parse(user)
        dispatch(saveUser(credentials))
      } else {
        dispatch(logoutUser())
      }
    } else {
      dispatch(logoutUser())
    }
  }

  return (
    <>
      {
        user.logged !== null && (
          <Router>
            <Switch>
              <PublicRoute
                exact
                path="/login"
                component={LoginPage}
                isAuthenticated={user.logged}
              />
              <PrivateRoute
                path="/"
                component={ProyectoXRouters}
                isAuthenticated={user.logged}
              />
            </Switch>
          </Router>
        )
      }
    </>


  );
};
