import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Div } from '../Div';
import { Button } from '../../atoms/Button';

export const AssignmentCalentarDialogStory = ({open,handleClose,item,storys}) => {

    const formatDate=(date)=>{
        date=date.replace('T',' ');
        date=date.substring(0,19);
        date=date.replace(new RegExp("-", "g"),'/');
        return date;
    }

    const formatComment=(comment)=>{
      comment=comment.replace(new RegExp("\n", "g"),'<br/>');
      return comment;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle>{item.name}{" - comentarios("}{storys.length}{")"}</DialogTitle>
        <DialogContent>
          <Div classname="dialog-story__container-modal">
            {storys.map((story)=>(
              <Div key={story.id} classname="dialog-story__container_comment" >
                  <Div classname="dialog-story__container_comment-info">{story.idCreatedBy} - {formatDate(story.createdAt)}</Div>
                  <div class="dialog-story__container_comment-text" dangerouslySetInnerHTML={{ __html: formatComment(story.text) }}/>
                  </Div>
            ))}              
          </Div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} classname="dialog-story__btn-close">Salir</Button>
        </DialogActions>
      </Dialog> 
    )
}
