import React, { useEffect, useState } from "react";
import { Image } from "../../atoms/Image";
import { Div } from "../Div";

import { Page } from "./Page";
import arrowleft from "../../../images/projects/arrow-left.png";
import arrowright from "../../../images/projects/arrow-right.png";
export const Paginador = ({ pageActual, totalPages, loadProject }) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    loadPagin();
  }, []);

  const loadPagin = () => {
    let pagesAux = [];
    for (let i = 1; i <= totalPages; i++) {
      pagesAux.push(i);
    }

    setPages(pagesAux);
  };

  const nextPage = () => {
    if (pageActual + 1 <= totalPages) {
      loadProject(pageActual + 1);
    }
  };
  const previousPage = () => {
    if (pageActual - 1 > 0) {
      loadProject(pageActual - 1);
    }
  };
  return (
    <Div classname={"paginador-area"}>
      <Div classname={"paginador"}>
        <Div onClick={previousPage} classname="paginador__arrow">
          <Image source={arrowleft} alt={"arrowleft"} />
        </Div>
        {pages.map((page, index) => (
          <Page key={index} page={page} loadProject={loadProject} />
        ))}

        <Div onClick={nextPage} classname="paginador__arrow">
          <Image source={arrowright} alt={"arrowleft"} />
        </Div>
      </Div>
    </Div>
  );
};
