import React from 'react'
import { Input } from '../../atoms/Input'
import { useState, useEffect } from 'react'
import { Div } from "../Div";
import { Popover } from '@material-ui/core';
import { AssignmentInputPopover } from './AssignmentInputPopover';
import { TextArea } from '../../atoms/TextArea';
import { Button } from '../../atoms/Button';

export const AssignmentInput = ({ initvalueE, initclassnameE, disabledE, 
                                  initvalueR, initclassnameR, disabledR, 
                                  date, changeSchedule,taskid,showMessage}) => {

    const [valueE, setValueE] = useState(initvalueE)
    const [classnameE, setClassnameE] = useState(initclassnameE)
    const [valueR, setValueR] = useState(initvalueR)
    const [valueRC, setValueRC] = useState(initvalueR)
    const [classnameR, setClassnameR] = useState(initclassnameR)
    const [anchorEl, setAnchorEl] = useState(null);
    const [commentTask, setCommentTask] = useState("");

    useEffect(() => {
        if(disabledR){
            setCommentTask("");
        }
    });    

    const handleChangeE = (ev) => {
        setValueE(ev.target.value)
        setClassnameE("assigncalentask__edited")
        changeSchedule(taskid, date, valueR, ev.target.value, "e","")
    }

    const handleChangeR = (ev) => {
        setValueR(ev.target.value)
        setClassnameR("assigncalentask__edited")
        changeSchedule(taskid, date, ev.target.value, valueE, "r")
    }

    const handleChangeRC = (ev) => {
        setValueRC(ev.target.value)
    }

    
 
    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleComment=()=>{
        if(!checkValidComment(commentTask)){
            showMessage('F',"El comentario no cumple con las condiciones establecidos (minimo dos caracteres y contener letras)");
            return;
        }
        if(valueRC!=null && valueRC>=0 && commentTask!=null && commentTask!=''){
            setValueR(valueRC);
            setClassnameR("assigncalentask__edited");
            setAnchorEl(null);
            setCommentTask(commentTask);
            changeSchedule(taskid, date, valueRC, valueE, "r",commentTask);
        }
    }

    const handleChangeComment=(ev)=>{
        setCommentTask(ev.target.value);
    }

    const checkValidComment = (comment) => {
        if(comment.length==2){
            const pattern = new RegExp('^[A-Z]+$', 'i');
            return pattern.test(comment)
        }else if(comment.length>2){
            const pattern = new RegExp('[a-zA-Z]{2,}', 'i');
            return pattern.test(comment);
        }else{
            return false;
        }
    }


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Div classname="assigncalentask__day">
            <Input value={valueE} classname={classnameE} disabled={disabledE}
                onChange={(ev) => handleChangeE(ev)}></Input>
            <Input value={valueR} classname={classnameR} disabled={disabledR} onClick={handleClickPopover}
                ></Input>
            <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                <Div classname='assignment-popup'>
                    <Div classname="assignment-popup__buttons">
                    <Div classname="assignment-popup__span">Tiempo ejecutado:</Div>
                    <AssignmentInputPopover
                       onChange={handleChangeRC}
                       valueR={valueRC}
                       placeholder=''
                    >
                    </AssignmentInputPopover>
                    </Div>
                    <TextArea classname="assignment-popup__comment" value={commentTask} onChange={handleChangeComment} placeholder="Ingrese el comentario"></TextArea>
                    <Div classname="assignment-popup__buttons">
                    <Button classname="assignment-popup__btn-comment" onClick={handleComment}>Guardar</Button>
                    <Button classname="assignment-popup__cancel" onClick={handleClosePopover}> Cancelar</Button>
                    </Div>
                </Div>
            </Popover>                
        </Div>
    )
}
