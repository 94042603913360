import React from "react";
import { Div } from "../../molecules/Div";
import { InfoUser } from "../../molecules/inicio/InfoUser";
import { Search } from "../../molecules/inicio/Search";

export const Header = () => {
  return (
    <Div classname={"header"}>
      <Div classname={"header__first"}>
        <Search />
      </Div>
      <InfoUser />
    </Div>
  );
};
