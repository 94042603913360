import React from "react";
import { Div } from "../../molecules/Div";
import { ItemMenu } from "../../molecules/inicio/ItemMenu";
import { LogoMenu } from "../../molecules/inicio/LogoMenu";

import inicio from "../../../images/inicio/incio.png";
import proyectos from "../../../images/inicio/proyecto.png";
import asignaciones from "../../../images/inicio/asignacion.png";
import usuario from "../../../images/inicio/usuario.png";

export const ListMenu = () => {
  return (
    <Div classname={"menu"}>
      <LogoMenu />
      <ItemMenu
        text={"Inicio"}
        sourceimge={inicio}
        alt={"incio"}
        path="/home"
      />
      <ItemMenu
        text={"Proyectos"}
        sourceimge={proyectos}
        alt={"proyectos"}
        path="/projects"
      />
      <ItemMenu
        text={"Asignaciones"}
        sourceimge={asignaciones}
        alt={"asignacion"}
        path="/assingment"
      />
      <ItemMenu
        text={"Usuarios"}
        sourceimge={usuario}
        alt={"usuario"}
        path="/users"
      />
    </Div>
  );
};
