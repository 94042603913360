import React from "react";
import { Button } from "../../atoms/Button";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import enter from "../../../images/detailproject/enter.png";
import adjunto from "../../../images/detailproject/adjunto.png";
import edit from "../../../images/inicio/time.png";

export const MenuHeader = ({ changeAS, actishedulling, name, sendShedulling }) => {
  return (
    <Div classname={"menuprojectdetail"}>
      <Div classname={"menuprojectdetail__title-functions"}>
        <Label
          text={name}
          classname={"menuprojectdetail__title"}
        />
        <Div classname={"menuprojectdetail__functions"}>
          <Button classname="menuprojectdetail__save-actions" onClick={sendShedulling}>
            <Image source={edit}/>
            Editar
          </Button>
          <Button classname={"menuprojectdetail__functions-button"}>
            <Image source={enter} />
          </Button>
          <Button classname={"menuprojectdetail__functions-button"}>
            <Image source={adjunto} />
          </Button>
        </Div>
      </Div>
      <Div classname={"menuprojectdetail__main-tabs"}>
        <Div classname={"menuprojectdetail__container-main-tabs"}>
          <Div classname={"menuprojectdetail__main-tab"} onClick={() => changeAS(true)}>
            Activity
            {actishedulling ? (
              <Div classname={"menuprojectdetail__main-tab-activity"}></Div>
            ) : null}
          </Div>
          <Div classname={"menuprojectdetail__main-tab"} onClick={() => changeAS(false)}>
            Shedulling
            {actishedulling == false ? (
              <Div classname={"menuprojectdetail__main-tab-shedulling"}></Div>
            ) : null}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};
