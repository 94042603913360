import React, { useState } from "react";
import { Input } from "../../atoms/Input";
import { Div } from "../Div";

import searchimg from "../../../images/inicio/search.png";
import { Image } from "../../atoms/Image";
import axios from "axios";
import { useSelector } from "react-redux";
import { Lin } from "../../atoms/Lin";

const baseUrl = global.backendproject;

export const Search = () => {
  const auth = useSelector((state) => state.auth);
  const [resultSearch, setResultSearch] = useState([]);
  const [search, setSearch] = useState("");

  const searchEnter = async (key) => {
    if (key === "Enter" && search.length > 3) {
      const { data } = await axios.get(
        `${baseUrl}/api/v1/project/projects/${auth.email}/${search}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
      
      setResultSearch(data);
    }else{
      setResultSearch([]);
    }
  };

  return (
    <Div classname={"header__search"}>
      <Image
        source={searchimg}
        alt={"search"}
        classname={"header__search-image"}
      />
      <Input
        placeholder={"Buscar"}
        classname={"header__input-search"}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => searchEnter(e.key)}
      />

      {resultSearch.length > 0 ? (
        <Div classname="header__result-search">
          {resultSearch.map((result) => (
            <Lin
              key={result.id}
              className="header__result-item"
              path={`/detailproject/${result.id}`}
            >
              {result.name}
            </Lin>
          ))}
        </Div>
      ) : null}
    </Div>
  );
};
