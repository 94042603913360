import React from 'react'
import { Div } from '../../molecules/Div'
import { Task } from '../../molecules/inicio/Task'
import { useState,useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import 'moment/locale/es';
import { useSelector, useDispatch } from "react-redux";


export const Tasks = () => {
    const baseUrl = global.backendproject;
    const [tasks, setTasks] = useState([]);
    const user = useSelector(state => state.auth)

    
    useEffect(() => {
        if(tasks.length===0){
            getTasks();
        }
    }, [])

    const getTasks = async() =>{
        const { data } = await axios.get(baseUrl+`/api/v1/task/gettaskbyuser?idAsanaUser=`+user.email+`&email=`+user.email, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
          });
        formatData(data);
    }

    const formatData = (data) => {
        let tasks =[]
        for(let i = 0; i<data.length;i++){
            if(!data[i].completed&&data[i].dueOn!==null){
                data[i].dueOn= moment(data[i].dueOn.substring(0,10), "YYYY-MM-DD");
                data[i].isToday = moment(data[i].dueOn).isSame(moment(), 'day'); 
                data[i].comments=getRandomInt(5,10);
                tasks.push(data[i])
            }
        }
        setTasks(tasks)
    }

    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
      }

    return (
        <Div classname={"tasks"}>
            {tasks.map((task)=>(
                <Task key={task.id} task={task}></Task>
            ))}
        </Div>
    )
}