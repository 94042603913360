import React from 'react'
import { Input } from '../../atoms/Input'
import { useState, useEffect } from 'react'
import { Div } from "../Div";

export const AssignmentFormInput = ({date, changeScheduleForm, initialValueE,disabledE, hoursDay}) => 
    {
    const [valueE, setValueE] = useState("")
    const [classnameE, setClassnameE] = useState("assigncalentask__est")
    const [classnameR, setClassnameR] = useState("assigncalentask__real")

    useEffect(() => {
        if(initialValueE!==undefined){
            setValueE(initialValueE)
        }
        if(initialValueE===0 || initialValueE===""){
            setClassnameE("assigncalentask__est");
        }
    }, [initialValueE])

    const handleChangeE = (ev) => {
        setValueE(ev.target.value);
        changeScheduleForm(date,ev.target.value)
        if(ev.target.value===0 || ev.target.value===""){
            setClassnameE("assigncalentask__est");
        }else{
            try{
                let inputv= parseFloat(ev.target.value);
                let sum=inputv+hoursDay;
                if(sum<=10){
                    setClassnameE("assigncalentask__edited");
                }else{
                    setClassnameE("assigncalentask__danger");  
                }
            }catch{
                setClassnameE("assigncalentask__danger");
            }
        }
    }

    return (
        <Div classname="assigncalentask__day">
            <Input value={valueE} classname={classnameE} disabled={disabledE}
                onChange={(ev) => handleChangeE(ev)}></Input>
            <Input value={""} classname={classnameR} disabled={true}/>
        </Div>
    )
}
