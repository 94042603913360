import React, { useState } from "react";

import { Image } from "../../atoms/Image";
import { Input } from "../../atoms/Input";
import { Div } from "../Div";
import imageperfil from "../../../images/inicio/image-user.png";
import { useSelector } from "react-redux";
import uuid from "uuid/v4";
import fileupload from "../../../images/detailproject/attach-outline.svg";
import { InputFile } from "../../atoms/InputFile";
import { Button } from "../../atoms/Button";
import axios from "axios";

const baseUrl = global.backendproject;
export const Footer = ({ task, addNewCommentLocal }) => {
  const [comment, setComment] = useState("");
  const user = useSelector((state) => state.auth);


  const assignComment = (e) => {
    setComment(e.target.value);
  };

  const newComment = async (type) => {
    const newCommentText = {
      attachmentName: "",
      createdAt: new Date().toString(),
      createdBy: {
        name: user.name,
      },
      id: uuid(),
      idTask: task.id,
      resourceSubtype: "comment_added",
      resourceType: "story",
      text: comment,
    };
    addNewCommentLocal(newCommentText);
    setComment("")
    /*
    const { data } = await axios.post(
      `${baseUrl}/api/v1/story/save?email=${user.email}`,
      {
        taskId: task.id,
        text: comment,
      },
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );
    */
  };

  const newFileCommentLocal = (e) => {
    const fileload = e.target.files[0];
    const newResource = {
      created_at: new Date(),
      download_url: "",
      host: "asana",
      id: uuid(),
      idTask: task.id,
      name: fileload.name,
      resource_type: "attachment",
      view_url: "",
    };
  }
  const newFileCommentServer = async (e) => {
     
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    const { data } = await axios.post(
      `${baseUrl}/api/v1/attachment/upload?taskId=${task.id}&email=${user.email}`,
      formData,
      {
        headers: {
          Authorization: "Basic YWRtaW46YWRtaW4=",
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  return (
    <Div classname={"footercard"}>
      <Div classname={"footercard__form"}>
        <Image source={imageperfil} classname={"footercard__form-image"} />
        <Input
          placeholder={"Realiza una actualización de la tarea"}
          classname={"footercard__form-input"}
          onChange={assignComment}
          onBlur={newComment}
          value={comment}
        />
        <Div classname="footercard__content-upload">
        <Button classname="footercard__content-add">
          <Image
            source={fileupload} alt={"upload file"}
            classname="footercard__icon-add"
          />
        </Button>
        <InputFile
          className={"footercard__inputfile"}
          onChange={newFileCommentServer}
        />
      </Div>
      
      </Div>
    </Div>
  );
};
