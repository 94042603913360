
import React, { useState } from "react";
import { DetailProjectBoard } from "./DetailProjectBoard";

import { DetailProjectList } from "./DetailProjectList";

export const DetailProjectTable = ({
  listBoard,
  task,
  onDragEnd,
  newSection,
  changenNewSection,
  createNewSection,
  newTask,
  selectTask,
}) => {
  return (
    <>
      {listBoard === "list" ? (
        <>
          <DetailProjectList
            onDragEnd={onDragEnd}
            task={task}
            selectTask={selectTask}
            newTask={newTask}
          />
        </>
      ) : null}
      {listBoard === "board" ? (
        <DetailProjectBoard
          task={task}
          onDragEnd={onDragEnd}
          newSection={newSection}
          changenNewSection={changenNewSection}
          createNewSection={createNewSection}
          newTask={newTask}
          selectTask={selectTask}
        />
      ) : null}
    </>
  );
};
