import React, { useEffect, useState } from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Div } from "../Div";
import checkblack from "../../../images/detailproject/check-black.png";
import { HeaderTableShedulling } from "./HeaderTableShedulling";
import { BodyTableShedulling } from "./BodyTableShedulling";

export const Shedulling = ({
  weeks,
  numweek,
  changeCalendar,
  shedulling,
  assignUser,
  changeShedulling,
}) => {
  return (
    <Div classname={"shedulling"}>
      <Label text={"Shedulling"} classname={"shedulling__title"} />
      <Div classname={"shedulling__hours"}>
        <Div classname={"shedulling__hours-space-1"}>
          <Label
            text={"Sheduled Hours"}
            classname={"shedulling__hours-text1"}
          />
          <Label text={"16"} classname={"shedulling__hours-text2"} />
        </Div>
        <Div classname={"shedulling__hours-space-2"}>
          <Div classname={"shedulling__check"}>
            <Image source={checkblack} />
            <Label text={"Over Allocated (+8)"} />
          </Div>
          <Div classname={"shedulling__check"}>
            <Image source={checkblack} />
            <Label text={"Fully Allocated (8)"} />
          </Div>
        </Div>
      </Div>
      <HeaderTableShedulling
        weeks={weeks}
        numweek={numweek}
        changeCalendar={changeCalendar}
      />
      <BodyTableShedulling
        shedulling={shedulling}
        assignUser={assignUser}
        numweek={numweek}
        changeShedulling={changeShedulling}
      />
    </Div>
  );
};
