import React from 'react'

export const Div = ({children,classname,contentEditable, onClick,  onKeyDown, onMouseLeave, hidden}) => {
    return (
        <div  className={classname} contentEditable={contentEditable} onClick={onClick}
         onKeyDown={onKeyDown} onMouseLeave={onMouseLeave} hidden={hidden}>
            {children}
        </div>
    )
}
