import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  formatDateOfResponse,
  formatDateCreateTask,
  formatDateRequest,
  getDateToRequest,
  getDaysOfWeek,
  getNameDayOfWeek,
  getNumberDayOfWeek,
} from "../../../helpers/horaMes";
import { Div } from "../../molecules/Div";
import { Footer } from "../../molecules/showDetailProject/Footer";
import { Header } from "../../molecules/showDetailProject/Header";
import { MenuHeader } from "../../molecules/showDetailProject/MenuHeader";
import { ContentShowDetailProject } from "../../organisms/showDetailProject/ContentShowDetailProject";

const baseUrl = global.backendproject;
export const ShowDetailProject = ({
  closeDetailProject,
  idtask,
  idproject,
  changeUserOfTask,
}) => {
  const user = useSelector((state) => state.auth);
  const [task, settask] = useState(null);

  const [actishedulling, setActishedulling] = useState(true);

  const changeAS = (value) => {
    setActishedulling(value);
  };

  const sendShedulling = () => {};
  const [weeks, setWeeks] = useState([]);

  const [shedulling, setShedulling] = useState({
    user: {
      name: "",
      email: "",
      idasana: 0,
    },
    shedulle: [
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
    ],
    shedulletwo: [
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
      {
        scheduleDate: "",
        estimated_time: null,
        real_time: 0,
      },
    ],
    totalHours: 0,
  });

  const [numweek, setNumWeek] = useState(1);

  const changeCalendar = (value) => {
    loadSheduling(value);
    loadCalendar(value);
    setNumWeek(value);
  };

  useEffect(() => {
    loadCalendar(1);
    loadSheduling(1);
    loadTask();
  }, []);
  const loadTask = async () => {
    if (idtask !== 0) {
      let { data } = await axios.get(
        `${baseUrl}/api/v1/task/findDetailbyid/${idtask.id}?email=${user.email}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
      console.log(data);
      settask(data);
    }
  };

  const onChangeDescription = (fieldState, text) => {
    settask({
      ...task,
      [fieldState]: text,
    });
  };
  const saveDescription = async () => {
    const requestUpdate = {
      name: task.name,
      idProject: [idproject],
      idSection: task.sectionId,
      idAsanaUser: task.asigned.asanaId,
      dueOn: formatDateCreateTask(task.due_on),
      startOn: formatDateCreateTask(task.start_on),
      taskId: task.id,
      completed: task.completed,
      notes: task.description,
    };

    const { data } = await axios.put(
      `${baseUrl}/api/v1/task/update?email=${user.email}`,
      requestUpdate,
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );
  };
  const loadSheduling = async (week) => {
    if (week === 1) {
      let { data } = await axios.get(
        `${baseUrl}/api/v1/schedule/get?taskid=${
          idtask.id
        }&startdate=${getDateToRequest(1)}&enddate=${getDateToRequest(7)}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
      if (data.schedule.length > 0) {
        let shedullingAux = shedulling.shedulle;
        for (let index = 0; index < data.schedule.length; index++) {
          if (
            data.schedule[index].scheduleDate !== null &&
            data.schedule[index].scheduleDate !== ""
          ) {
            let position =
              formatDateOfResponse(data.schedule[index].scheduleDate) - 1;
            shedullingAux[position].estimated_time =
              data.schedule[index].estimated_time;
            shedullingAux[position].scheduleDate =
              data.schedule[index].scheduleDate;
          }
        }
        let weekone = [1, 2, 3, 4, 5, 6, 7];
        shedullingAux = shedullingAux.map((date, index) => {
          date.scheduleDate = formatDateRequest(weekone[index]);
          return date;
        });

        setShedulling({
          ...shedulling,
          user:
            shedulling.user.name.length > 0
              ? shedulling.user
              : {
                  name: data.asignedName == null ? "" : data.asignedName,
                  email: data.asignedEmail == null ? "" : data.asignedEmail,
                  idasana: data.asanaUserId,
                },
          shedulle: shedullingAux,
          totalHours: shedullingAux
            .filter((date) => date.estimated_time !== null)
            .reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.estimated_time,
              0
            ),
        });
      } else {
        let shedullingAux = shedulling.shedulle;
        let weekone = [1, 2, 3, 4, 5, 6, 7];
        shedullingAux = shedullingAux.map((date, index) => {
          date.scheduleDate = formatDateRequest(weekone[index]);
          return date;
        });
        setShedulling({
          ...shedulling,
          user:
            shedulling.user.name.length > 0
              ? shedulling.user
              : {
                  name: data.asignedName == null ? "" : data.asignedName,
                  email: data.asignedEmail == null ? "" : data.asignedEmail,
                  idasana: data.asanaUserId,
                },
          shedulle: shedullingAux,
          totalHours: shedullingAux
            .filter((date) => date.estimated_time !== null)
            .reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.estimated_time,
              0
            ),
        });
      }
    } else {
      let { data } = await axios.get(
        `${baseUrl}/api/v1/schedule/get?taskid=${
          idtask.id
        }&startdate=${getDateToRequest(8)}&enddate=${getDateToRequest(14)}`,
        {
          headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        }
      );
      if (data.schedule.length > 0) {
        let shedullingAux = shedulling.shedulletwo;
        for (let index = 0; index < data.schedule.length; index++) {
          if (
            data.schedule[index].scheduleDate !== null &&
            data.schedule[index].scheduleDate !== ""
          ) {
            let position =
              formatDateOfResponse(data.schedule[index].scheduleDate) - 1;
            shedullingAux[position].estimated_time =
              data.schedule[index].estimated_time;
            shedullingAux[position].scheduleDate =
              data.schedule[index].scheduleDate;
          }
        }
        let weektwo = [8, 9, 10, 11, 12, 13, 14];
        shedullingAux = shedullingAux.map((date, index) => {
          date.scheduleDate = formatDateRequest(weektwo[index]);
          return date;
        });
        setShedulling({
          ...shedulling,
          user:
            shedulling.user.name.length > 0
              ? shedulling.user
              : {
                  name: data.asignedName == null ? "" : data.asignedName,
                  email: data.asignedEmail == null ? "" : data.asignedEmail,
                  idasana: data.asanaUserId,
                },
          shedulletwo: shedullingAux,
          totalHours: shedullingAux
            .filter((date) => date.estimated_time !== null)
            .reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.estimated_time,
              0
            ),
        });
      } else {
        let shedullingAux = shedulling.shedulletwo;
        let weektwo = [8, 9, 10, 11, 12, 13, 14];
        shedullingAux = shedullingAux.map((date, index) => {
          date.scheduleDate = formatDateRequest(weektwo[index]);
          return date;
        });
        setShedulling({
          ...shedulling,
          user:
            shedulling.user.name.length > 0
              ? shedulling.user
              : {
                  name: data.asignedName == null ? "" : data.asignedName,
                  email: data.asignedEmail == null ? "" : data.asignedEmail,
                  idasana: data.asanaUserId,
                },
          shedulletwo: shedullingAux,
          totalHours: shedullingAux
            .filter((date) => date.estimated_time !== null)
            .reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.estimated_time,
              0
            ),
        });
      }
    }
  };
  const loadCalendar = (week) => {
    let weekone = [1, 2, 3, 4, 5, 6, 7];
    let weektwo = [8, 9, 10, 11, 12, 13, 14];

    let finalArray = [];

    if (week === 1) {
      weekone.forEach((day) => {
        let calendar = {
          dayName: getNameDayOfWeek(day),
          dayNumber: getNumberDayOfWeek(day),
        };

        finalArray.push(calendar);
      });
    } else {
      weektwo.forEach((day) => {
        let calendar = {
          dayName: getNameDayOfWeek(day),
          dayNumber: getNumberDayOfWeek(day),
        };
        finalArray.push(calendar);
      });
    }
    setWeeks(finalArray);
  };

  const assignUser = async (value) => {
    changeUserOfTask(value, {
      section: task.sectionId,
      task: idtask.idtask,
    });
    const requestUptate = {
      name: task.name,
      idProject: [idproject],
      idSection: task.sectionId,
      idAsanaUser: value.asanaId,
      dueOn: task.due_on,
      startOn: task.start_on,
      taskId: idtask.id,
      completed: task.completed,
      notes: task.description,
    };
    const { data } = await axios.put(
      `${baseUrl}/api/v1/task/update?email=${user.email}`,
      requestUptate,
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );
    setShedulling({
      ...shedulling,
      user: value,
    });
  };
  const changeShedulling = (value, position, week) => {
    if (week === 1) {
      let shedullingAux = shedulling.shedulle;

      shedullingAux[position].estimated_time =
        value.length === 0
          ? null
          : /^([0-9])*$/.test(value) === true
          ? parseInt(value)
          : null;

      setShedulling({
        ...shedulling,
        shedulle: shedullingAux,
        totalHours: shedullingAux
          .filter((date) => date.estimated_time !== null)
          .reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.estimated_time,
            0
          ),
      });
    } else {
      let shedullingAux = shedulling.shedulletwo;

      shedullingAux[position].estimated_time =
        value.length === 0
          ? null
          : /^([0-9])*$/.test(value) === true
          ? parseInt(value)
          : null;

      setShedulling({
        ...shedulling,
        shedulletwo: shedullingAux,
        totalHours: shedullingAux
          .filter((date) => date.estimated_time !== null)
          .reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.estimated_time,
            0
          ),
      });
    }
  };

  const addNewResourceLocal = (resource) => {
  
    const newResource = { ...task };

    newResource.attachments.push(resource);

    settask(newResource);
  };

  const addNewCommentLocal = (comment) => {
    const newComment = { ...task };
    newComment.stories.push(comment);
    settask(newComment);
  };
  return (
    <>
      {task !== null ? (
        <Div classname={"showdetailproject"}>
          <Div classname={"showdetailproject__main"}>
            <Header closeDetailProject={closeDetailProject} />
            <MenuHeader
              changeAS={changeAS}
              actishedulling={actishedulling}
              name={idtask.name}
              sendShedulling={sendShedulling}
            />
            <ContentShowDetailProject
              actishedulling={actishedulling}
              weeks={weeks}
              numweek={numweek}
              changeCalendar={changeCalendar}
              shedulling={shedulling}
              assignUser={assignUser}
              changeShedulling={changeShedulling}
              task={task}
              addNewResourceLocal={addNewResourceLocal}
              onChangeDescription={onChangeDescription}
              saveDescription={saveDescription}
            />
          </Div>
          <Div classname={"showdetailproject__footer"}>
            <Footer task={task} addNewCommentLocal={addNewCommentLocal} />
          </Div>
        </Div>
      ) : null}
    </>
  );
};
