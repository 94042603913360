import React,{ useState,useEffect,useRef } from 'react';
import { useSelector } from "react-redux";
import Fab from '@material-ui/core/Fab';
import { useSnackbar } from 'notistack';
import SockJsClient from 'react-stomp';
import { Div } from "../../molecules/Div";
import { Popover } from '@material-ui/core';
import { Button } from '../../atoms/Button';
import addNotification from 'react-push-notification';



export const Notification = () => {
    const SOCKET_URL = global.wsnotification;
    const user = useSelector(state => state.auth);
    const [userFree, setUserFree] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isNewMessage,setIsNewMessage] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;    

    useInterval(() => {
      showNotification();
    }, 60000);


    useEffect(() => {
    }, []);

    let onMessageReceived = (data) => {
      setIsNewMessage(true);
      let dataList=data.userTaskFrees;
      if(user.roleId===3) dataList=dataList.filter( ntf => ntf.groupId === user.groupId);
      setUserFree(dataList);
    } 

    const showNotification = async ()=>{
      if((user.roleId==1 || user.roleId==2 || user.roleId==3) && userFree!=null && userFree.length>0 && isNewMessage ){
        let i=0;
        let data=userFree;
        let globalMessage='';
        for(i in data){
          let hour;
          if(data[i].hourFree % 1 == 0) hour=data[i].hourFree;
          else hour=(Math.round(data[i].hourFree * 100) / 100).toFixed(2);
          let message=data[i].userName+' Tiene '+hour+' Horas libres ';
          globalMessage+=message+"\n";
          enqueueSnackbar(message);
          await sleep(2000);
        }
        if(globalMessage!==''){
          addNotification({
              title: 'Uranus',
              message: globalMessage,
              theme: 'darkblue',
              native: true,
              duration: 7200000
          });
        }
        setIsNewMessage(false);
      }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    const openPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };    

    const showDataUserFree = (data) => {
      let hour;
      if(data.hourFree % 1 == 0) hour=data.hourFree;
      else hour=(Math.round(data.hourFree * 100) / 100).toFixed(2);
      let message=data.userName+' Tiene '+hour+' Horas libres';
      return (
        <Div key={data.email} classname="notification-popup__span">{message}</Div>
      );
    }    

    return (
        <div>
          { (user.roleId==1 || user.roleId==2 || user.roleId==3) && 
            ( <SockJsClient
                url={SOCKET_URL}
                topics={['/topic/message']}
                onConnect={console.log("Connected!!")}
                onDisconnect={console.log("Disconnected!")}
                onMessage={msg => onMessageReceived(msg)}
                debug={false}
              /> 
            )
           }
          <Div classname='notification'>
            { (userFree!=null && userFree.length>0) && 
              (
              <Fab size="small" aria-label="add" onClick={openPopover}>
                {userFree.length+'!'}
              </Fab>)
            } 
            <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{ vertical: 'top',horizontal: 'left',}}
                    transformOrigin={{vertical: 'bottom',horizontal: 'right',}}
                >
                <Div classname='notification-popup'>
                    <Div classname="notification-popup__buttons">
                      {(userFree!=null && userFree.length>0) && userFree.map((data)=>(
                        showDataUserFree(data)
                      ))}                       
                      <Button classname="notification-popup__cancel" onClick={closePopover}>&#10006;</Button>
                    </Div>
                </Div>
            </Popover>            
          </Div>         
        </div>
    )
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
