import React, { useState } from "react";
import { Div } from "../../molecules/Div";
import { InputUser } from "../../molecules/users/InputUser";
import { ListProfileTeam } from "./ListProfileTeam";

export const FormUsers = ({ handleForm, choosePerfil, chooseTeam, chooseSenority, form }) => {
  const [list, setList] = useState({
    perfiles: ["ADMIN", "ECOMMERCE_MANAGER", "LEADER", "REGULAR"],
    teams: [
      "FRONTEND",
      "BACKEND",
      "PAUTA",
      "ECOMMERCE_MANAGER",
      "SEO",
      "DEVOPS",
      "COMERCIAL",
      "DISEÑO",
      "PROCESOS&QA"
    ],
    senorityList: [
      "JUNIOR",
      "SENIOR",
      "EXPERTO",
      "NIVEL MEDIO"
    ]
  });

  const currencyFormat = (num) => {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
    <Div classname="formusers">
      <Div classname="formusers__inputs">
        <InputUser
          label={"Miembro nuevo"}
          placeholder="Nombre y apellidos"
          handleForm={handleForm}
          names={"names"}
          value={form.names}
        />
        <InputUser
          label={"Correo"}
          placeholder="Correo del nuevo miembro"
          handleForm={handleForm}
          names={"email"}
          value={form.email}
        />
      </Div>
      <Div classname="formusers__inputs">
        <ListProfileTeam
          label={"Perfil"}
         
          lists={list.perfiles}
          select={choosePerfil}
          textSelected={form.perfil}
        />
        <ListProfileTeam
          label={"Equipo"}
      
          lists={list.teams}
          select={chooseTeam}
          textSelected={form.team}
        />
      </Div>
      <Div classname="formusers__inputs">
        <ListProfileTeam
          label={"Seniority"}
         
          lists={list.senorityList}
          select={chooseSenority}
          textSelected={form.seniority}
        />
        <InputUser
          label={"Valor Hora"}
          placeholder="Valor de Hora"
          handleForm={handleForm}
          names={"valueHour"}
          value={currencyFormat(form.valueHour)}
          disabled={true}
        />
      </Div>      
      <Div classname="formusers__inputs">
      <InputUser
          label={"AccessToken"}
          placeholder="Token del nuevo miembro"
          handleForm={handleForm}
          names={"accessToken"}
          value={form.accessToken}
        />
      </Div>
    </Div>
  );
};
