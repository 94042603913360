import React from 'react'
import { AssignmentCalendarFooter } from '../../molecules/assignments/AssignmentCalendarFooter'
import { AssignmentCalendarHeader } from '../../molecules/assignments/AssignmentCalendarHeader'
import { AssignmentCalendarTask } from '../../molecules/assignments/AssignmentCalendarTask'
import { AssignmentTableHeader } from '../../molecules/assignments/AssignmentTableHeader'
import { useState, useEffect } from "react";
import moment from 'moment';
import axios from "axios";
import { AssignmentItem } from './AssignmentItem'
import { Div } from '../../molecules/Div'

export const AssignmentTable = ({ teamselected, allUsers }) => {
    const baseUrlP = global.backendproject;
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)
    const [range, setRange] = useState("")
    const [week, setWeek] = useState(["YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD", "YYYY-MM-DD"])
    const [info, setInfo] = useState([])
    const [teamDayTotal, setTeamDayTotal] = useState(["", "", "", "", "", "", ""])
    const [teamTotalR, setTeamTotalR] = useState(0)
    const [teamTotalE, setTeamTotalE] = useState(0)
    const [weeknumber, setWeekNumber] = useState(1)
    const [allProjects, setAllProjects] = useState([])
    const [allSections, setAllSections] = useState([])


    useEffect(() => {
        if (dateFrom === null) {
            getDates();
        }
        if(allProjects.length===0){
            getProjects()
        }
        if(allSections.length===0){
            getSections()
        }
        if (teamselected.name !== "") {
            getInfo()
        }
    }, [teamselected, range])

    const getDates = () => {
        let dayn = moment().day()
        let mome1 = moment().subtract(dayn - 1, 'days')
        let mome2 = moment().add(7 - dayn, 'days')
        let mo = moment().subtract(dayn - 1, 'days');
        setDateFrom(mome1)
        setDateTo(mome2)
        let r = mome1.format("MMM DD YYYY") + ", " + mome2.format("MMM DD YYYY")
        setRange(r)
        let array = [];
        for (let i = 0; i < 7; i++) {
            if (i === 0) {
                let m = mo.add(i, 'days')
                array.push(m.format("YYYY-MM-DD"))
            } else {
                let m = mo.add(1, 'days')
                array.push(m.format("YYYY-MM-DD"))
            }
        }
        setWeek(array)
        setWeekNumber(1)
    }

    const weekLess = () => {
        if (weeknumber > 0) {
            let df7 = dateFrom.subtract(7, 'days')
            let dt7 = dateTo.subtract(7, 'days')
            setDateFrom(df7)
            setDateTo(dt7)
            let r = df7.format("MMM DD YYYY") + ", " + dt7.format("MMM DD YYYY")
            setRange(r)
            let mo = moment(dateFrom);
            let array = [];
            for (let i = 0; i < 7; i++) {
                if (i === 0) {
                    let m = mo.add(i, 'days')
                    array.push(m.format("YYYY-MM-DD"))
                } else {
                    let m = mo.add(1, 'days')
                    array.push(m.format("YYYY-MM-DD"))
                }
            }
            setWeek(array)
            setWeekNumber(weeknumber - 1)
        }
    }

    const weekPlus = () => {
        if (weeknumber < 2) {
            let df7 = dateFrom.add(7, 'days')
            let dt7 = dateTo.add(7, 'days')
            setDateFrom(df7)
            setDateTo(dt7)
            let r = df7.format("MMM DD YYYY") + ", " + dt7.format("MMM DD YYYY")
            setRange(r)
            let mo = moment(dateFrom);
            let array = [];
            for (let i = 0; i < 7; i++) {
                if (i === 0) {
                    let m = mo.add(i, 'days')
                    array.push(m.format("YYYY-MM-DD"))
                } else {
                    let m = mo.add(1, 'days')
                    array.push(m.format("YYYY-MM-DD"))
                }
            }
            setWeek(array)
            setWeekNumber(weeknumber + 1)
        }
    }

    const getInfo = async () => {
        let url = baseUrlP + `/api/v1/task/assignmentbygroup?groupid=` + teamselected.id + `&startdate=` + dateFrom.format("YYYY-MM-DD") + `&enddate=` + dateTo.format("YYYY-MM-DD");
        const { data } = await axios.get(url, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        });
        formatInfo(data.users)
    }

    const getProjects = async () => {
        let url = baseUrlP + `/api/v1/project/findall`;
        const { data } = await axios.get(url, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        });
        setAllProjects(data)
    }

    const getSections = async () => {
        let url = baseUrlP + `/api/v1/section/findall`;
        const { data } = await axios.get(url, {
            headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
        });
        setAllSections(data)
    }

    const formatInfo = (data) => {
        data.sort(function (a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        for (let i = 0; i < data.length; i++) {
            let hoursWeek = [];
            for (let k = 0; k < week.length; k++) {
                let weekhour = new Object();
                weekhour.date = week[k];
                weekhour.realh = 0;
                weekhour.estimatedh = 0;
                hoursWeek.push(weekhour)
            }
            for (let j = 0; j < data[i].task.length; j++) {
                for (let k = 0; k < hoursWeek.length; k++) {
                    for (let l = 0; l < data[i].task[j].schedule.length; l++) {
                        if (hoursWeek[k].date === data[i].task[j].schedule[l].scheduleDate.substring(0, 10)) {
                            hoursWeek[k].realh += data[i].task[j].schedule[l].real_time;
                            hoursWeek[k].estimatedh += data[i].task[j].schedule[l].estimated_time;
                            data[i].task[j].schedule[l].comment="";
                        }
                    }
                }
            }
            data[i].hoursWeek = hoursWeek;
            let totalr = 0;
            let totale = 0;
            for (let m = 0; m < hoursWeek.length; m++) {
                totalr += hoursWeek[m].realh;
                totale += hoursWeek[m].estimatedh;

            }
            data[i].totale = totale;
            data[i].totalr = totalr;
        }
        let tr = 0;
        let te = 0;
        for (let i = 0; i < data.length; i++) {
            te += data[i].totale;
            tr += data[i].totalr;
        }
        let arr = [0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].hoursWeek.length; j++) {
                arr[j] += data[i].hoursWeek[j].estimatedh
            }
        }
        setTeamDayTotal(arr)
        setTeamTotalR(tr);
        setTeamTotalE(te);
        setInfo(data);
    }

    return (
        <Div classname="assignment-table">
            <AssignmentTableHeader range={range} weekLess={weekLess} weekPlus={weekPlus} />
            <AssignmentCalendarHeader weekLess={weekLess} weekPlus={weekPlus} week={week} />
            {info.map((item) => (
                <AssignmentItem key={item.email} item={item} getInfo={getInfo}
                    allProjects={allProjects} allSections={allSections} weeknumber={weeknumber} 
                    allUsers={allUsers}></AssignmentItem>
            ))}
            <AssignmentCalendarFooter teamDayTotal={teamDayTotal} teamTotalR={teamTotalR} teamTotalE={teamTotalE} />
        </Div>
    )
}