import axios from "axios";
import React, { useEffect, useState } from "react";
import { OptionsDetailBoardList } from "../../molecules/detailproject/OptionsDetailBoardList";
import { DetailProjectTable } from "../../organisms/detailproject/DetailProjectTable";

import uuid from "uuid/v4";
import { ShowDetailProject } from "../showdetailproject/ShowDetailProject";
import { useSelector } from "react-redux";
import { formatDateCreateTask } from "../../../helpers/horaMes";

const baseUrl = global.backendproject;
export const DetailProject = ({ match }) => {
  const auth = useSelector((state) => state.auth);
  const [taskSelected, setTaskSelected] = useState(0);
  const [filter, setFilter] = useState("all");
  const [listBoard, setlistBoard] = useState("list");
  const [state, setState] = useState(0);
  const [project, setProject] = useState("");
  const [newSection, setNewSection] = useState("");

  const move = (
    state,
    source,
    destination,
    droppableSource,
    droppableDestination
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    let response = state.map((section) => {
      if (section.id === droppableSource.droppableId) {
        section.taskList = sourceClone;
        return section;
      }
      if (section.id === droppableDestination.droppableId) {
        section.taskList = destClone;
        return section;
      }
      return section;
    });
    return response;
  };

  const reorder = (source, state, list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    let res = state.map((section) => {
      if (section.id === source) {
        section.taskList = result;
        return section;
      }
      return section;
    });

    return res;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        let arraySourceReorder = state.filter((section) => {
          return section.id === destination.droppableId;
        });
        let responses = reorder(
          destination.droppableId,
          state,
          arraySourceReorder[0].taskList,
          source.index,
          destination.index
        );

        setState(responses);
        break;

      default:
        let arraySource = state.filter((section) => {
          return section.id === source.droppableId;
        });
        let arrayDestination = state.filter((section) => {
          return section.id === destination.droppableId;
        });

        changeTaskSection(arraySource, destination, source);

        let response = move(
          state,
          arraySource[0].taskList,
          arrayDestination[0].taskList,
          source,
          destination
        );

        setState(response);

        break;
    }
  };

  useEffect(() => {
    loadNameProject();
    loadTasks(filter);
  }, []);

  const changeTaskSection = async (arraySource, destination, source) => {
    const taskSource = arraySource[0].taskList[source.index].id;
    const newSection = destination.droppableId;
    const { data } = await axios.put(
      `${baseUrl}/api/v1/task/changesection?idTask=${taskSource}&idNewSection=${newSection}&email=${auth.email}`,
      {},
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );
  };

  const loadNameProject = async () => {
    let { data } = await axios.get(
      `${baseUrl}/api/v1/project/findbyid/${match?.params.idproject}?email=${auth.email}`,
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );
    setProject(data.name);
  };

  const loadTasks = async (filter) => {
    const { data } = await axios.get(
      `${baseUrl}/api/v1/section/findSectionbyidProject/${match?.params.idproject}?page=1&maxPage=100&filtro=${filter}&sort=ASC&email=${auth.email}`,
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );

    setState(data.content);
  };

  const handleListBoard = (value) => {
    if (listBoard === "list" && value === "board") {
      setlistBoard(value);
    }
    if (listBoard === "board" && value === "list") {
      setlistBoard(value);
    }
  };

  const changenNewSection = (e) => {
    setNewSection(e.target.value);
  };
  const createNewSection = async () => {
    let formatnewSection = {
      id: uuid(),
      name: newSection,
      taskList: [],
    };

    const { data } = await axios.post(
      `${baseUrl}/api/v1/section/save?email=${auth.email}`,
      {
        name: newSection,
        projectId: match?.params.idproject,
      },
      {
        headers: { Authorization: "Basic YWRtaW46YWRtaW4=" },
      }
    );

    state.push(formatnewSection);
    setState(state);
    setNewSection("");
  };

  const newTask = async (id, form) => {
    const dueOn = formatDateCreateTask(form.date[0].endDate);
    const startOn = formatDateCreateTask(form.date[0].startDate);
    const newHomework = {
      name: form.task,
      idProject: [match?.params.idproject],
      idSection: id,
      idAsanaUser: form.responsable.asanaId,
      dueOn: dueOn,
      startOn: startOn === dueOn ? "" : startOn,
    };

    const {
      data,
    } = await axios.post(
      `${baseUrl}/api/v1/task/save?email=${auth.email}`,
      newHomework,
      { headers: { Authorization: "Basic YWRtaW46YWRtaW4=" } }
    );

    const newHomeworkState = {
      completed: false,
      completedAt: "",
      createdAt: new Date().toLocaleString(),
      due_on: form.date[0].endDate,
      id: uuid(),
      idProject: match?.params.idproject,
      name: form.task,
      sectionId: id,
      start_on: form.date[0].startDate,
      storys: 0,
      tags: [],
      user: form.responsable,
    };

    setState(
      state.map((section) => {
        if (section.id === id) {
          section.taskList.unshift(newHomeworkState);
          return section;
        }
        return section;
      })
    );
  };

  const changeFilter = (criteria) => {
    setFilter(criteria);
    loadTasks(criteria);
  };

  const selectTask = (objectTask) => {
    setTaskSelected(objectTask);
  };

  const closeDetailProject = () => {
    setTaskSelected(0);
  };

  const changeUserOfTask = (user, idSectionidTask) => {
    setState(
      state.map((section) => {
        if (section.id === idSectionidTask.section) {
          section.taskList = section.taskList.map((task) => {
            if (task.id === idSectionidTask.task) {
              task.user = user;
              return task;
            } else {
              return task;
            }
          });
          return section;
        } else {
          return section;
        }
      })
    );
  };

  return (
    <>
      {taskSelected !== 0 && (
        <ShowDetailProject
          closeDetailProject={closeDetailProject}
          idtask={taskSelected}
          idproject={match?.params.idproject}
          changeUserOfTask={changeUserOfTask}
        />
      ) }

      <OptionsDetailBoardList
        changeFilter={changeFilter}
        handleListBoard={handleListBoard}
        project={project}
        filter={filter}
        listBoard={listBoard}
      />
      {state !== 0 ? (
        <DetailProjectTable
          listBoard={listBoard}
          task={state}
          onDragEnd={onDragEnd}
          newSection={newSection}
          changenNewSection={changenNewSection}
          createNewSection={createNewSection}
          newTask={newTask}
          selectTask={selectTask}
          closeDetailProject={closeDetailProject}
        />
      ) : null}
    </>
  );
};
