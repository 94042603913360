import React from "react";
import { Image } from "../../atoms/Image";
import { Label } from "../../atoms/Label";
import { Span } from "../../atoms/Span";
import { Div } from "../Div";
import arrowdown from "../../../images/detailproject/arrow-down.png";
import { Button } from "../../atoms/Button";
import edit from "../../../images/inicio/time.png";
export const HeaderUsers = ({saveNewUser}) => {
  return (
    <Div classname="headerusers">
      <Label text={"Usuario"} classname="headerusers__title" />
      <Button classname={"headerusers__button"} onClick={saveNewUser}>
        <Image source={edit} />
        Crear
      </Button>
      <Div classname={"headerusers__select"}>
        <Span classname={"headerusers__team"}>Equipo</Span>
        <Span classname={"headerusers__whichteam"}>Design team</Span>
        <Image source={arrowdown} classname={"headerusers__arrow"} />
      </Div>
    </Div>
  );
};
