import React from "react";
import { TextArea } from "../../atoms/TextArea";
import { Div } from "../Div";

export const DescriptionProject = ({
  description,
  onChangeDescription,
  saveDescription,
}) => {
  return (
    <Div classname="description">
      <TextArea
        classname="description__textarea"
        value={description}
        onChange={(e) => onChangeDescription("description", e.target.value)}
        onBlur={saveDescription}
      ></TextArea>
    </Div>
  );
};
