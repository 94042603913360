import React from "react";
import { RowProjectDetail } from "../../molecules/detailproject/RowProjectDetail";
import { Div } from "../../molecules/Div";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { OptionsSprint } from "../../molecules/detailproject/OptionsSprint";
import { OptionsDetail } from "../../molecules/detailproject/OptionsDetail";

import styled from "styled-components";

const Item = styled.div``;




const List = styled.div``;



const Container = styled(List)``;



export const DetailProjectList = ({ onDragEnd, task, selectTask, newTask }) => {
  return (
    <Div classname={"list"}>
      <OptionsDetail />
      <DragDropContext onDragEnd={onDragEnd}>
        {task.map((list, i) => (
          <Droppable key={list.id} droppableId={list.id}>
            {(provided, snapshot) => (
              <Container
                innerRef={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                className="list__content"
              >
                <OptionsSprint title={list.name} id={list.id} newTask={newTask}/>
                {list.taskList.length
                  ? list.taskList.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Item
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                            {...provided.dragHandleProps}
                            className="list__item"
                          >
                            <RowProjectDetail task={item} selectTask={selectTask}/>
                          </Item>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </Div>
  );
};
